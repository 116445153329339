import { Box, Stack } from "@mui/material";
import React from "react";
import MainHeader from "../../shared/MainHeader";
// import { GET } from "../../api/ApiService";
// import EncryptionHelper from "../../shared/EncryptionHelper";
// import DataTable from "react-data-table-component";

// let columns = [
//   {
//     name: <div>Case Name</div>,
//     sortable: true,
//     selector: (row) => (
//       <span style={{ cursor: "pointer", color: "#01997D" }}>
//         {row.CaseName}
//       </span>
//     ),
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Case File Name</div>,
//     selector: (row) => row.caseFileName,
//     sortable: true,
//     wrap: true,
//     // grow: 2,

//     // width: "10%",
//   },
//   {
//     name: <div>Comments</div>,
//     selector: () => <span>comment</span>,
//     sortable: true,
//     wrap: true,
//     width: "500px",
//     // grow: 2,
//   },
// ];

const SharedDoc = () => {
  // const [fileData, setFileData] = useState([]);

  // useEffect(() => {
  //   GET("file/get-all-files")
  //     .then((response) => {
  //       const encryptedData = response?.encryptedData;
  //       const decryptedData = EncryptionHelper.decryptData(encryptedData);

  //       console.log("decryptedData of all files =>", decryptedData);
  //       setFileData(decryptedData?.files);
  //     })
  //     .catch((err) => {
  //       console.warn("err ==>", err);
  //     });
  // }, []);

  return (
    <Box>
      <MainHeader headingText={"Shared Documents"} />
      <Stack
        gap={3}
        p={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        {/* <DataTable
          // title="Cases"
          columns={columns}
          data={fileData}
          // progressPending={loading}
          // selectableRows={permissionsIdArray?.includes(23) ? true : false}
          // onSelectedRowsChange={handleChange}
          // pagination
          // paginationServer
          // paginationTotalRows={totalRows}
          // onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
          // clearSelectedRows={toggledClearRows}
        /> */}
      </Stack>
    </Box>
  );
};

export default SharedDoc;

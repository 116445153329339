import React from "react";

import { Box } from "@mui/material";
import MainHeader from "../../shared/MainHeader";
// import CasesTable from "../../shared/CasesTable";
// import moment from "moment";
import PvtCaseOnHoldTable from "../../components/PvtCaseHoldTable";

const PvtcaseOnHold = () => {
  // const columnsData = [
  //   {
  //     name: <div>Ready to Assign</div>,
  //     selector: (row) => (row.ReadyToAssign === 1 ? "true" : "false"),
  //     sortable: true,
  //   },
  //   {
  //     name: <div>Received Date</div>,
  //     selector: (row) =>
  //       row.ReceivedDate ? moment(row.ReceivedDate).format("LLL") : "null",
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Adjourned Date</div>,
  //     selector: (row) =>
  //       row.AdjournedDate ? moment(row.AdjournedDate).format("LLL") : "null",
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Report</div>,
  //     selector: (row) => row.Report,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Court</div>,
  //     selector: (row) => row.Court,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Judge</div>,
  //     selector: (row) => row.Judge,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Preferred Availability</div>,
  //     selector: (row) => row.PreferredAvailability,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  // ];

  return (
    <Box>
      <MainHeader headingText={"Private Cases on Hold"} />

      {/* <CasesTable
        columnsData={columnsData}   
        status={"open"}
        caseType={"Private"}
        showComments={true}
        readyToAssign={false}
      /> */}

      <PvtCaseOnHoldTable />
    </Box>
  );
};

export default PvtcaseOnHold;

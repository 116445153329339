import React from "react";

// mui icons
import { InputAdornment, TextField } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const EmailInput = ({
  name,
  placeholder,
  onChangeValue,
  onBlurValue,
  value,
}) => {
  return (
    <TextField
      type={"email"}
      name={name}
      placeholder={placeholder}
      fullWidth
      color="black"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        ),
      }}
      onChange={onChangeValue}
      onBlur={onBlurValue}
      value={value}
      sx={{
        "& .MuiInputBase-root": {
          height: "45px",
        },
      }}
    />
  );
};

export default EmailInput;

import { Box, Stack, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import MainHeader from "../../shared/MainHeader";
import VisitingForm from "../../shared/Form/US/VisitingForm";
import CustodialForm from "../../shared/Form/US/CustodialForm";
import CustodialFormSpanish from "../../shared/Form/Spanish/CustodialForm";
import VisitingFormSpanish from "../../shared/Form/Spanish/VisitingForm";

const ClientForm = () => {
  const [tabValue, setTabValue] = useState("one");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <MainHeader headingText="Client Forms" />

      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          textColor="shamrock"
          indicatorColor="shamrock"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#3DCBB1",
            },
          }}
        >
          <Tab
            sx={{
              fontSize: "15px",
              "&.Mui-selected": {
                color: "#019479",
                fontSize: "18px",
              },
            }}
            value="one"
            label="Visiting Parents"
            wrapped
          />
          <Tab
            sx={{
              fontSize: "15px",
              "&.Mui-selected": {
                color: "#019479",
                fontSize: "18px",
              },
            }}
            value="two"
            label="Custodial Parents"
          />
          <Tab
            sx={{
              fontSize: "15px",
              "&.Mui-selected": {
                color: "#019479",
                fontSize: "18px",
              },
            }}
            value="three"
            label="Visiting Parents (Spanish)"
          />
          <Tab
            sx={{
              fontSize: "15px",
              "&.Mui-selected": {
                color: "#019479",
                fontSize: "18px",
              },
            }}
            value="four"
            label="Custodial Parents (Spanish)"
          />
        </Tabs>
      </Box>

      {/* visiting form  */}
      {tabValue === "one" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <VisitingForm />
        </Stack>
      )}

      {/* custodial form  */}
      {tabValue === "two" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <CustodialForm />
        </Stack>
      )}

      {/* Visiting Form Spanish */}
      {tabValue === "three" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <VisitingFormSpanish />
        </Stack>
      )}

      {/* Custodial Form Spanish  */}
      {tabValue === "four" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <CustodialFormSpanish />
        </Stack>
      )}
    </Box>
  );
};

export default ClientForm;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editNotes: {},
};

export const editNotesSlice = createSlice({
  name: "editnotes",
  initialState,
  reducers: {
    storeEditNotes: (state, action) => {
      state.editNotes = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeEditNotes } = editNotesSlice.actions;

export default editNotesSlice.reducer;

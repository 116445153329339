import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editDar: {},
};

export const editDarSlice = createSlice({
  name: "editdar",
  initialState,
  reducers: {
    storeEditDar: (state, action) => {
      state.editDar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeEditDar } = editDarSlice.actions;

export default editDarSlice.reducer;

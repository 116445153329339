import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../EncryptionHelper";

const UserSearchDropdown = ({
  name,
  label,
  collaboratorsData,
  value,
  onChange,
  onBlur,
  error,
  touched,
  reset,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (reset) {
      setInputValue("");
      setOptions([]);
    }
  }, [reset]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (value.length > 1) {
      setLoading(true);
      GET(`users?search=${value}`)
        .then((response) => {
          const encryptedData = response?.encryptedData;
          const decryptedData = EncryptionHelper.decryptData(encryptedData);
          console.log("decryptedData of search user=>", decryptedData);
          const dropdownData = decryptedData?.users?.map((item) => {
            return {
              label: item.Username,
              id: item.UserID,
            };
          });

          let newarr = dropdownData;
          if (collaboratorsData?.length > 0) {
            console.log("collaboratorsData ==", collaboratorsData);

            for (let i = 0; i < collaboratorsData.length; i++) {
              const filteredDropdown = newarr?.filter(
                (item) => item.id !== collaboratorsData[i]
              );

              newarr = filteredDropdown;
            }
            setOptions(newarr);
          } else {
            setOptions(dropdownData);
          }
        })
        .catch((err) => {
          console.warn(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  };

  return (
    <div>
      <Autocomplete
        name={name}
        options={options}
        getOptionLabel={(option) => option?.label}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        loading={loading}
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: 0,
            paddingLeft: "9px",
            // Hover color using theme
            "&:hover fieldset": {
              borderColor: theme.palette.black.main, // Dynamic hover color
            },
            // Focused color using theme
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.black.main, // Same color for focus
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="Search User..."
            variant="outlined"
            error={touched && Boolean(error)}
            helperText={touched && error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        clearOnBlur={false}
      />
    </div>
  );
};

export default UserSearchDropdown;

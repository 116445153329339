import React, { useState, useEffect } from "react";
import { GET, POST } from "../../api/ApiService";
import EncryptionHelper from "../EncryptionHelper";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import ButtonCommon from "../ButtonCommon";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  TablePagination,
  IconButton,
} from "@mui/material";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import SingleFileInput from "../SingleFileInput";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import TextInput from "../TextInput";
import { useLocation } from "react-router-dom";

const headerConfig = [
  { id: "title", label: "Title", sortable: false },
  { id: "Case", label: "Case", sortable: true },
  { id: "Download", label: "Download", sortable: true },
  { id: "Created at", label: "Created at", sortable: true },
  { id: "Created by", label: "Created by", sortable: true },
];

const AllCourtsTable = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cid = searchParams.get("CID");

  const [selectedFiles, setSelectedFiles] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [caseDetails, setCaseDetails] = useState();

  console.log("data ====>", data);

  useEffect(() => {
    fetchUsers();
    getCaseDetails();
  }, [page, rowsPerPage]);

  const getCaseDetails = () => {
    GET(`case/get-case/${cid}`)
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        setCaseDetails(decryptedData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const fetchUsers = () => {
    setLoading(true);

    GET(
      `court-report/show?id=${cid}&pageNumber=${
        page + 1
      }&pageSize=${rowsPerPage}`
    )
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        console.log("resp ss decryptedData ==>", decryptedData);

        setData(decryptedData?.reports);
        setTotalRows(decryptedData?.totalData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const addReportHandler = () => {
    setOpenDialog(true);
  };

  const handleFileChange = (files) => {
    setSelectedFiles(files);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      // if (page > 1) {
      onPageChange(event, page - 1);
      // }
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();

      formData.append("Title", values.title);
      formData.append("CaseID", caseDetails?.CaseID);
      formData.append("CreatedByUserID", userDetails?.id);
      formData.append("FilePath", selectedFiles);

      POST("court-report/create", formData, true, true)
        .then((resp) => {
          toast.success(resp?.message);
          fetchUsers(page);
          handleDialogClose();
          resetForm();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <ButtonCommon btnClicked={addReportHandler}>
          Add new report
        </ButtonCommon>
      </Stack>

      {loading ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headerConfig.map((header) => (
                        <TableCell key={header.id}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row?.CaseID}>
                        <TableCell>{row?.Title}</TableCell>
                        <TableCell>{row?.CaseName}</TableCell>
                        <TableCell>
                          {row.fileName && (
                            <DownloadIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                const link = document.createElement("a");
                                link.href = `${process.env.REACT_APP_FILE_UPLOADS}/${row.fileName}`;
                                console.log(
                                  "file download  ==>",
                                  `${process.env.REACT_APP_FILE_UPLOADS}/${row.fileName}`
                                );
                                link.target = "_blank";
                                link.download = row.FileName;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {row?.CreatedAt
                            ? moment(row?.CreatedAt).format("LL")
                            : "null"}
                        </TableCell>
                        <TableCell>{row?.CreatedByUser}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} height={200}>
              No cases found
            </Stack>
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
          }}
        >
          <Typography variant="h4">Add Report</Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {/* row one */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Title<span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <TextInput
                      name="title"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.title}
                      startIcon={false}
                    />

                    {formik.touched.title && formik.errors.title ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>

              {/* row two */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Case<span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <TextInput value={caseDetails?.CaseName} disabled={true} />
                  </Stack>
                </Grid>
              </Grid>

              {/* row four */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                ></Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <SingleFileInput onFileChange={handleFileChange} />
                </Grid>
              </Grid>

              {/* row four */}
              <Grid container item>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <ButtonCommon type="submit">Save</ButtonCommon>
                  <ButtonCommon btnClicked={handleDialogClose}>
                    Cancel
                  </ButtonCommon>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Dialog>
    </>
  );
};

export default AllCourtsTable;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import AutocompleteInput from "../AutocompleteInput";
import DateInput from "../DateInput";
import ButtonCommon from "../ButtonCommon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import SelectInput from "../SelectInput";
import MainHeader from "../MainHeader";
import { useNavigate } from "react-router-dom";
import { PUT } from "../../api/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EncryptionHelper from "../EncryptionHelper";
import { statusData } from "../../utils/selectActivity";

const EditDar = () => {
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const caseDropdown = useSelector((state) => state.allcaseReducer.allcase);
  const editData = useSelector((state) => state.editDarReducer.editDar);

  console.log("edit DAta ==", editData);

  // Calculate the date 1 days ago
  const dateLimit = new Date();
  dateLimit.setDate(dateLimit.getDate() - 1);

  const formik = useFormik({
    initialValues: {
      case: { value: editData?.CaseID, label: editData?.CaseName },
      activity: editData?.ServiceOrActivity,
      activityStart: editData?.StartTime,
      activityEnd: editData?.FinishTime,
    },
    validationSchema: Yup.object({
      case: Yup.object().required("Case is required"),
      activity: Yup.string().required("Activity is required"),
      activityStart: Yup.date()
        .required("Start date is required")
        .test(
          "is-within-24-hours",
          "Start date cannot be more than 24 hours before the end date",
          function (value) {
            const { activityEnd } = this.parent;
            return activityEnd
              ? value >=
                  new Date(activityEnd).setHours(
                    new Date(activityEnd).getHours() - 24
                  )
              : true;
          }
        ),
      activityEnd: Yup.date()
        .required("End date is required")
        .min(Yup.ref("activityStart"), "End date must be after start date"),
    }),
    onSubmit: (values) => {
      if (editData?.Exported === 1) {
        toast.error(
          "This activity cannot be changed because it has already been billed."
        );
        return;
      }

      const putData = {
        CaseID: values.case.value,
        FinishTime: values.activityEnd,
        Exported: false,
        ServiceOrActivity: values.activity,
        StartTime: values.activityStart,
        ModifiedByUserID: userDetails?.id,
      };

      console.log("data to be encrypted =>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT(`daily-activity/update?id=${editData?.ReportID}`, { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          navigate("/mydar");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  return (
    <Box>
      <MainHeader headingText="Update DAR" />
      <Stack
        p={4}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.92px",
                textAlign: "left",
              }}
            >
              Update Daily Activity Report
            </Typography>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              {/* 1  */}
              <Grid item xs={6}>
                <Stack>
                  {" "}
                  <Typography sx={{ textAlign: "left" }}>
                    Case<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <AutocompleteInput
                      id="case"
                      name="case"
                      options={caseDropdown}
                      value={formik.values.case}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("case", newValue);
                      }}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.case && Boolean(formik.errors.case)}
                      // helperText={formik.touched.case && formik.errors.case}
                    />
                    {formik.touched.case && formik.errors.case ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.case}
                      </div>
                    ) : null}{" "}
                  </Stack>
                </Stack>
              </Grid>

              {/* 2  */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Select Activity<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <SelectInput
                      name="activity"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activity}
                      dropDownData={statusData}
                      helperText=""
                    />
                    {formik.touched.activity && formik.errors.activity ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activity}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 3 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Activity Start Date & Time
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityStart"
                      placeholder="activityStart"
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityStart}
                      helperText={""}
                    />
                    {formik.touched.activityStart &&
                    formik.errors.activityStart ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityStart}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 4 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Activity end Date & Time
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityEnd"
                      placeholder="activityEnd"
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityEnd}
                      helperText={""}
                    />
                    {formik.touched.activityEnd && formik.errors.activityEnd ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityEnd}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 5 */}
              <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"flex-start"} gap={4}>
                  <ButtonCommon type="submit">Save</ButtonCommon>
                  <ButtonCommon
                    btnClicked={() => {
                      navigate("/mydar");
                    }}
                  >
                    Cancel
                  </ButtonCommon>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(EditDar);

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST } from "../../api/ApiService";
import { useNavigate } from "react-router-dom";
import EncryptionHelper from "../../shared/EncryptionHelper";

const VerifyOtp = ({ verifyOtpHandler }) => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");

  const otpSubmitHandler = () => {
    const encryptData = EncryptionHelper.encryptData({
      otp: otp,
    });

    POST(`verify-otp`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
        verifyOtpHandler();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const resendOtpHandler = () => {
    const email = localStorage.getItem("passResetEmail");

    const encryptData = EncryptionHelper.encryptData({
      email: email,
    });

    POST(`send-otp`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Stack width="80%" gap={4}>
      <Box>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ width: "10px" }}></span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
          inputStyle={{
            width: "50px",
            height: "50px",
            borderRadius: "4px",
            marginBottom: "10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "right",
            color: "#969AB8",
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "23px",
            letterSpacing: "0.1px",
            cursor: "pointer",
          }}
          onClick={resendOtpHandler}
        >
          Resend OTP
        </Typography>
      </Box>
      <Button variant="contained" onClick={otpSubmitHandler}>
        Verify OTP
      </Button>

      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          navigate("/login");
        }}
      >
        Go back to login
      </Button>
    </Stack>
  );
};

export default VerifyOtp;

import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";

const CaseAdjourn = () => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);

  // const apiData = [
  //   {
  //     caseName: "Case 1",
  //     adjournedDate: "2024-10-20",
  //   },
  //   {
  //     caseName: "Case 2",
  //     adjournedDate: "2024-10-15",
  //   },
  // ];

  // Fetching data and converting to event format

  // useEffect(async () => {
  //   try {
  //     const response = await GET(`case/case-adjournment`);

  //     const encryptedData = response?.encryptedData;
  //     const decryptedData = EncryptionHelper.decryptData(encryptedData);
  //     console.log("decryptedData of case adjournment respo =>", decryptedData);

  //     const eventList = decryptedData.map((item) => {
  //       // Convert date string to Date object and add default time (9:00 AM)
  //       const adjournedDate = new Date(`${item.AdjournedDate}T09:00:00`);
  //       const endDate = new Date(adjournedDate.getTime() + 60 * 60 * 1000); // 1 hour duration

  //       return {
  //         title: `${item.CaseName} Adjourned`,
  //         start: adjournedDate,
  //         end: endDate,
  //       };
  //     });
  //     setEvents(eventList);
  //   } catch (err) {
  //     console.warn("err --------", err);
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GET(`case/case-adjournment`);

        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        console.log(
          "decryptedData of case adjournment respo =>",
          decryptedData
        );

        const eventList = decryptedData.map((item) => {
          const adjournedDate = new Date(`${item.AdjournedDate}T09:00:00`);
          const endDate = new Date(adjournedDate.getTime() + 60 * 60 * 1000);

          return {
            title: `${item.CaseName} Adjourned`,
            start: adjournedDate,
            end: endDate,
          };
        });
        setEvents(eventList);
      } catch (err) {
        console.warn("err --------", err);
      }
    };

    fetchData();
  }, []); // Ensure dependency array is provided, here it's empty as you need it to run only once

  // Function to format the title with case name and adjourned date
  const eventTitleFormatter = (event) => {
    const formattedDate = moment(event.start).format("MMMM Do YYYY, h:mm a");
    return `${event.title} - Adjourned on ${formattedDate}`;
  };

  return (
    <Box>
      <MainHeader headingText="Case Adjournment Calendar" />
      <Stack
        p={4}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "19.32px",
            fontWeight: "400",
            lineHeight: "23.53px",
            textAlign: "left",
          }}
        >
          The calendar shows when cases are being adjourned. The cases appear in
          the order in which they are adjourned.
        </Typography>

        <div style={{ height: 500 }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ margin: "0 20px" }}
            components={{
              event: ({ event }) => <span>{eventTitleFormatter(event)}</span>,
            }}
          />
        </div>
      </Stack>
    </Box>
  );
};

export default CaseAdjourn;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editCase: {},
};

export const editCaseSlice = createSlice({
  name: "editCase",
  initialState,
  reducers: {
    storeEditCase: (state, action) => {
      state.editCase = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeEditCase } = editCaseSlice.actions;

export default editCaseSlice.reducer;

import { Box } from "@mui/material";
import React from "react";
import MainHeader from "../../shared/MainHeader";
import { Grid, Stack, Typography } from "@mui/material";
import TextInput from "../../shared/TextInput";
import { useSelector } from "react-redux";

const Profile = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  return (
    <Box>
      <MainHeader headingText={"My Profile"} />

      <Box
        sx={{
          padding: "25px 20px 25px 20px",
          gap: "20px",
          borderRadius: "12px",
          border: "1px solid #C1C1C1",
        }}
      >
        <Grid container rowSpacing={4} columnSpacing={8}>
          {/* 1  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>Name</Typography>
              <Stack sx={{ width: "100%" }}>
                {" "}
                <TextInput
                  name="caseNumber"
                  disabled={true}
                  placeholder=""
                  value={userDetails.name}
                  startIcon={false}
                />
              </Stack>
            </Stack>
          </Grid>
          {/* 2  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>Email</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="caseName"
                  disabled={true}
                  placeholder=""
                  value={userDetails.email}
                  startIcon={false}
                />
              </Stack>
            </Stack>
          </Grid>
          {/* 3  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>Role</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="caseName"
                  disabled={true}
                  placeholder=""
                  value={
                    userDetails.role === 5
                      ? "Social Worker"
                      : userDetails.role === 4
                      ? "Supervisor"
                      : userDetails.role === 3
                      ? "Billing"
                      : userDetails.role === 2
                      ? "Operations"
                      : userDetails.role === 1
                      ? "Administrator"
                      : userDetails.role === 6
                      ? "Associate"
                      : ""
                  }
                  startIcon={false}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;

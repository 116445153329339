import { MenuItem, Select, Chip, Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const SelectInput = ({
  name,
  placeholder,
  onChangeValue,
  onBlurValue,
  value,
  helperText,
  disabled = false,
  showPleaseSelect = true,
  dropDownData = [
    {
      value: 0,
      label: "label",
    },
  ],
  isMulti = false, // New prop to handle multiselect
}) => {
  const themeColor = useSelector((state) => state.themeColorReducer.lightColor);

  return (
    <Select
      name={name}
      placeholder={placeholder}
      fullWidth
      onChange={onChangeValue}
      onBlur={onBlurValue}
      value={value}
      variant="outlined"
      disabled={disabled}
      helperText={helperText}
      color="black"
      multiple={isMulti} // Conditionally set multiple attribute
      sx={{
        width: "100%",
        "& .MuiInputBase-input": {
          padding: "6px 20px",
        },
        "& .Mui-disabled": {
          background: themeColor ? "rgb(45, 45, 50)" : "rgb(233,236,239)",
        },
      }}
      renderValue={(selected) =>
        isMulti ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((selectedValue) => (
              <Chip
                key={selectedValue}
                label={
                  dropDownData.find((option) => option.value === selectedValue)
                    ?.label
                }
              />
            ))}
          </Box>
        ) : (
          dropDownData.find((option) => option.value === selected)?.label ||
          "Please select"
        )
      }
    >
      {showPleaseSelect &&
        !isMulti && ( // Show "Please select" only for single select
          <MenuItem value={null}>Please select</MenuItem>
        )}
      {dropDownData?.map((option) => (
        <MenuItem key={option?.value} value={option?.value}>
          {option?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectInput;

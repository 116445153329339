import { Box } from "@mui/material";
import React, { useEffect } from "react";
import MainHeader from "../../shared/MainHeader";
import { GET } from "../../api/ApiService";
import { storeAllCase } from "../../slices/allCaseSlice";
import { useDispatch } from "react-redux";
// import EncryptionHelper from "../../shared/EncryptionHelper";
// import { storeAllUser } from "../../slices/alluserSlice";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const DashboardPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    GET(`case/search-case?search=`)
      .then((resp) => {
        dispatch(storeAllCase(resp?.data));
      })
      .catch((err) => {
        console.warn(err);
      });

    // GET("users")
    //   .then((resp) => {
    //     const encryptedData = resp?.encryptedData;
    //     const decryptedData = EncryptionHelper.decryptData(encryptedData);
    //     dispatch(storeAllUser(decryptedData?.users));
    //   })
    //   .catch((err) => {
    //     toast.error(err?.response?.data?.message);
    //   });
  }, []);

  return (
    <Box>
      <MainHeader headingText={"Dashboard Overview"} />
    </Box>
  );
};

export default DashboardPage;

import React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  CssBaseline,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MicrosoftLogin from "react-microsoft-login";

// assets
import logo from "../../assets/logo.png";
import unsplash from "../../assets/loginpage/unsplash.png";

// mui icons
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PasswordInput from "../../shared/PasswordInput";
import { useDispatch } from "react-redux";
import { storeUserDetails } from "../../slices/userSlice";
import { POST } from "../../api/ApiService";
import EmailInput from "../../shared/EmailInput";
import EncryptionHelper from "../../shared/EncryptionHelper";

const baseURl = process.env.REACT_APP_API_BASE_URL;
const apiKEY = process.env.REACT_APP_API_KEY;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const forgotClickHandler = () => {
    navigate("/forgotpassword");
  };

  const signUpClickHandler = () => {
    navigate("/signup");
  };

  const authHandler = (err, data) => {
    console.warn(err, data);
    if (data) {
      axios
        .post(
          `${baseURl}/microsoft-auth`,
          {
            accessToken: data.accessToken,
          },
          {
            headers: {
              Authorization: `Bearer ${data.accessToken}`,
              "X-API-Key": apiKEY,
            },
          }
        )
        .then((resp) => {
          toast.success(resp?.message);
          localStorage.setItem("token", resp?.data?.token);
          dispatch(storeUserDetails(resp?.data?.user));
          navigate("/dashboard");
        })
        .catch((err) => {
          console.warn("err of our api =>", err);
        });

      // navigate("/dashboard");

      // axios
      //   .get("https://graph.microsoft.com/v1.0/me", {
      //     headers: {
      //       Authorization: `Bearer ${data.accessToken}`,
      //     },
      //   })
      //   .then((resp) => {
      //     console.log("resp of get user data =>", resp);
      //   })
      //   .catch((err) => {
      //     console.log("err of get user data =>", err);
      //   });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      console.log("data to be encrypted ==>", {
        email: values.email,
        password: values.password,
      });

      const encryptData = EncryptionHelper.encryptData({
        email: values.email,
        password: values.password,
      });

      POST("login", { encryptData }, false)
        .then((resp) => {
          toast.success(resp?.message);
          localStorage.setItem("token", resp?.token);
          console.log("resp of login api ==>", resp.user);
          dispatch(storeUserDetails(resp?.user));
          navigate("/dashboard");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <Box sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Box sx={{ height: "100vh", pb: 4 }}>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "225px",
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
              ></img>
            </Stack>

            <form onSubmit={formik.handleSubmit}>
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={2}
                width="60%"
                m="auto"
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "45px",
                    letterSpacing: "0.10000000149011612px",
                    textAlign: "center",
                    cursor: "default",
                  }}
                >
                  Log In
                </Typography>

                <Box sx={{ width: "100%" }}>
                  <EmailInput
                    name="email"
                    placeholder="Email"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <PasswordInput
                    name="password"
                    placeholder="Password"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.password}
                    </div>
                  ) : null}
                </Box>
                <Button type="submit" variant="contained" fullWidth>
                  Log In
                </Button>
                <Stack direction="row" justifyContent="flex-end" width={"100%"}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#0062FF",
                      cursor: "pointer",
                    }}
                    onClick={forgotClickHandler}
                  >
                    Forgot password?
                  </Typography>
                </Stack>

                <Stack direction="row">
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#969AB8",
                      cursor: "default",
                    }}
                  >
                    or
                  </Typography>
                </Stack>

                <MicrosoftLogin
                  clientId={"0533a5b3-b2d6-4d5d-8304-19d5a9af111d"}
                  authCallback={authHandler}
                />

                <Stack
                  direction="row"
                  gap={1}
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#969AB8",
                      cursor: "default",
                    }}
                  >
                    Don't have an account?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#0062FF",
                      cursor: "pointer",
                    }}
                    onClick={signUpClickHandler}
                  >
                    Sign Up
                  </Typography>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Grid>
        {!belowTablet && (
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100vh",
                backgroundImage: `url(${unsplash})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <Box sx={{ width: "70%", margin: "auto" }}>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "32px",
                    fontWeight: "500",
                    lineHeight: "44px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                  }}
                >
                  The future belongs to those who believe in the beauty of their
                  dreams.
                </Typography>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "36px",
                    letterSpacing: "-0.02em",
                    textAlign: "right",
                  }}
                >
                  - Eleanor Roosevelt
                </Typography>
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { DELETE, GET, PUT } from "../../api/ApiService";
import EncryptionHelper from "../EncryptionHelper";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeEditNotes } from "../../slices/editnotesSlice";
import TextInput from "../TextInput";
import TextAreaInput from "../TextAreaInput";
import ButtonCommon from "../ButtonCommon";
import PreviewIcon from "@mui/icons-material/Preview";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import drawerLogo from "../../assets/logo.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useLocation } from "react-router-dom";

let headerConfig = [
  { id: "actions", label: "Actions", sortable: false },
  { id: "Created at", label: "Created at", sortable: true },
  { id: "Modified at", label: "Modified at", sortable: true },
  { id: "CaseName", label: "Case Name", sortable: true },
  { id: "Author", label: "Author", sortable: true },
  { id: "Subject", label: "Subject", sortable: true },
  { id: "Note", label: "Note", sortable: true },
  { id: "Attachments", label: "Attachments", sortable: true },
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    // if (page > 1) {
    onPageChange(event, page - 1);
    // }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

const AllNotesTable = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const editNotesData = useSelector((state) => state.editnoteReducer.editNotes);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cid = searchParams.get("CID");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [openDialog, setOpenDialog] = useState();
  const [editData, setEditData] = useState({});
  const [notesData, setNotesData] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [openDocs, setOpenDocs] = useState(false);
  const [docsData, setDocsData] = useState("");
  const [pdfData, setPdfData] = useState([]);

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const permissionsIdArray = userDetails?.permission?.map((item) => {
    return item.permission_id;
  });

  if (!permissionsIdArray?.includes(24)) {
    headerConfig = headerConfig.filter((item) => item.id !== "actions");
  }

  useEffect(() => {
    GET(`case-notes/fetch?CaseID=${cid}`)
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        setPdfData(decryptedData?.caseNotes);
      })
      .catch((err) => {
        console.warn("err ==>", err);
      });
  }, []);

  const fetchUsers = (page) => {
    setLoading(true);

    GET(
      `case-notes/fetch?CaseID=${cid}&pageNumber=${page}&pageSize=${rowsPerPage}`
    )
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        setData(decryptedData?.caseNotes);
        setTotalRows(decryptedData?.totalData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  };

  const deleteBtnHandler = () => {
    const deleteData = {
      ids: selected,
    };
    console.log("data to be encrypted =>", deleteData);
    const encryptData = EncryptionHelper.encryptData(deleteData);
    DELETE(`case-notes/delete`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
        setToggleClearRows(!toggledClearRows);
        fetchUsers(1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const handleCloseDocs = () => {
    setOpenDocs(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: editData?.Subject,
      case: editData?.CaseName,
      note: editData?.Note,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        Subject: values.subject,
        Note: values.note,
        ModifiedByUserID: userDetails?.id,
      };

      console.log("data to be encrypted =>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT(`case-notes/update/${editNotesData?.NoteID}`, { encryptData })
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  // const generatePDF = () => {
  //   const doc = new jsPDF();

  //   const logo = drawerLogo;
  //   const letterhead = {
  //     company: "Comprehensive Family Services",
  //     services: "Forensic & Therapeutic Services",
  //     address: "450 Lexington Ave, 4th Floor, New York, NY, 10007",
  //     website: "www.cfs-nyc.com",
  //     phone: "(212) 267-2670",
  //   };

  //   const addLetterhead = () => {
  //     if (logo) {
  //       doc.addImage(logo, "PNG", 20, 10, 50, 20);
  //     }
  //     doc.setFontSize(12);
  //     doc.text(letterhead.company, 80, 15);
  //     doc.setFontSize(10);
  //     doc.text(letterhead.services, 80, 20);
  //     doc.text(letterhead.address, 80, 25);
  //     doc.text(letterhead.website, 80, 30);
  //     doc.text(letterhead.phone, 80, 35);
  //   };

  //   const headers = [
  //     ["Note", "Subject", "Case Name", "Created At", "Created By User"],
  //   ];
  //   const data = pdfData?.map((elt) => [
  //     elt.Note,
  //     elt.Subject,
  //     elt.CaseName,
  //     moment(elt.CreatedAt).format("LL"),
  //     elt.CreatedByUser,
  //   ]);

  //   addLetterhead();

  //   doc.autoTable({
  //     head: headers,
  //     body: data,
  //     startY: 50, // First page content starts after the letterhead
  //     margin: { top: 60 }, // Ensures content on subsequent pages doesn't overlap with the letterhead
  //     theme: "striped",
  //     didDrawPage: (data) => {
  //       // Add letterhead on every page
  //       if (data.pageNumber > 1) {
  //         addLetterhead();
  //       }
  //     },
  //   });

  //   const pageCount = doc.internal.getNumberOfPages();
  //   for (let i = 1; i <= pageCount; i++) {
  //     doc.setPage(i);
  //     doc.setFontSize(10);
  //     doc.text(
  //       `Page ${i} of ${pageCount}`,
  //       doc.internal.pageSize.width - 30,
  //       doc.internal.pageSize.height - 10
  //     );
  //   }

  //   doc.save(`${data[0][2]}_notes.pdf`);
  // };

  const generatePDF = () => {
    const doc = new jsPDF();

    const logo = drawerLogo;
    const letterhead = {
      company: "Comprehensive Family Services",
      services: "Forensic & Therapeutic Services",
      address: "450 Lexington Ave, 4th Floor, New York, NY, 10007",
      website: "www.cfs-nyc.com",
      phone: "(212) 267-2670",
    };

    const addLetterhead = () => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const leftMargin = 10;
      const rightMargin = 20;
      const logoWidth = 50;
      const logoHeight = 20;
      const textStartX = pageWidth - rightMargin - 80; // Adjust this value to position the text

      // Add the logo
      if (logo) {
        doc.addImage(logo, "PNG", leftMargin, 10, logoWidth, logoHeight);
      }

      // Add the company details on the right
      doc.setFontSize(12);
      doc.text(letterhead.company, textStartX, 15);
      doc.setFontSize(10);
      doc.text(letterhead.services, textStartX, 20);
      doc.text(letterhead.address, textStartX, 25);
      doc.text(letterhead.website, textStartX, 30);
      doc.text(letterhead.phone, textStartX, 35);
    };

    const headers = [
      ["Note", "Subject", "Case Name", "Created At", "Created By User"],
    ];
    const data = pdfData?.map((elt) => [
      elt.Note,
      elt.Subject,
      elt.CaseName,
      moment(elt.CreatedAt).format("LL"),
      elt.CreatedByUser,
    ]);

    addLetterhead();

    doc.autoTable({
      head: headers,
      body: data,
      startY: 50,
      margin: { top: 60 },
      theme: "striped",
      didDrawPage: (data) => {
        if (data.pageNumber > 1) {
          addLetterhead();
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width - 30,
        doc.internal.pageSize.height - 10
      );
    }

    doc.save(`${data[0][2]}_notes.pdf`);
  };

  // mui table functions

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.NoteID);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={2}
      >
        <ButtonCommon btnClicked={generatePDF}>Export All Notes</ButtonCommon>
        {selected?.length > 0 && (
          <Button
            variant="contained"
            color="red"
            sx={{
              color: "white",
            }}
            onClick={deleteBtnHandler}
          >
            Delete
          </Button>
        )}
      </Stack>

      {loading ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {sortedData?.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 && selected.length < data.length
                          }
                          checked={
                            data.length > 0 && selected.length === data.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>

                      {headerConfig.map((header) => (
                        <TableCell key={header.id}>
                          {header.sortable ? (
                            <TableSortLabel
                              active={orderBy === header.id}
                              direction={orderBy === header.id ? order : "asc"}
                              onClick={() => handleRequestSort(header.id)}
                            >
                              {header.label}
                            </TableSortLabel>
                          ) : (
                            header.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row) => {
                      const isItemSelected =
                        selected.indexOf(row.NoteID) !== -1;
                      return (
                        <TableRow
                          key={row.NoteID}
                          role="checkbox"
                          selected={isItemSelected}
                          onClick={(event) => {
                            // Prevent row click from affecting checkbox selection
                            event.stopPropagation();
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, row.NoteID)
                              }
                              // Prevent checkbox click from triggering the row click event
                              onClick={(event) => event.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell>
                            <EditIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setEditData(row);
                                dispatch(storeEditNotes(row));
                                setOpenDialog(true);
                                // navigate("/updatenote");
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ cursor: "pointer", color: "#01997D" }}
                          >
                            {row.CreatedAt
                              ? moment(row.CreatedAt).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>
                            {row.UpdatedAt
                              ? moment(row.UpdatedAt).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>{row.CaseName}</TableCell>
                          <TableCell>{row.CreatedByUser}</TableCell>
                          <TableCell>{row.Subject}</TableCell>
                          <TableCell>
                            {
                              <PreviewIcon
                                onClick={() => {
                                  setNotesData(row.Note);
                                  setOpenNotes(true);
                                }}
                              />
                            }
                          </TableCell>
                          <TableCell>
                            {row.FileName &&
                              (row?.FileName?.includes(".txt") ||
                              row?.FileName?.includes(".pdf") ||
                              row?.FileName?.includes(".jpg") ||
                              row?.FileName?.includes(".jpeg") ||
                              row?.FileName?.includes(".png") ? (
                                <AttachFileIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setDocsData(row.FileName);
                                    setOpenDocs(true);
                                  }}
                                />
                              ) : (
                                <DownloadIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = `${process.env.REACT_APP_FILE_UPLOADS}/${row.FileName}`;
                                    link.download = row.FileName;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                  }}
                                />
                              ))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} height={200}>
              No cases found
            </Stack>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={handleClose}>
        <Box>
          <Stack p={4} pt={2} pb={2}>
            <Typography variant="h4">Update Note</Typography>
          </Stack>
          <Stack
            p={4}
            gap={0}
            sx={{
              border: "1px solid #C1C1C1",
              // minHeight: "80vh",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container rowSpacing={2}>
                {/* row one */}
                <Grid container item>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography sx={{ textAlign: "left" }}>
                      Subject<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      <TextInput
                        name="subject"
                        placeholder=""
                        onChangeValue={formik.handleChange}
                        onBlurValue={formik.handleBlur}
                        value={formik.values.subject}
                        startIcon={false}
                      />

                      {formik.touched.subject && formik.errors.subject ? (
                        <div
                          style={{
                            fontSize: "smaller",
                            color: "red",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.subject}
                        </div>
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
                {/* row two */}
                <Grid container item>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography sx={{ textAlign: "left" }}>
                      Case<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      <TextInput
                        name="case"
                        placeholder=""
                        disabled={true}
                        onChangeValue={formik.handleChange}
                        onBlurValue={formik.handleBlur}
                        value={formik.values.case}
                        startIcon={false}
                      />

                      {formik.touched.case && formik.errors.case ? (
                        <div
                          style={{
                            fontSize: "smaller",
                            color: "red",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.case}
                        </div>
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
                {/* row two */}
                <Grid container item>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography sx={{ textAlign: "left" }}>
                      Note<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      <TextAreaInput
                        name="note"
                        placeholder=""
                        onChangeValue={formik.handleChange}
                        onBlurValue={formik.handleBlur}
                        value={formik.values.note}
                        rows={10}
                        helperText=""
                      />
                      {formik.touched.note && formik.errors.note ? (
                        <div
                          style={{
                            fontSize: "smaller",
                            color: "red",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.note}
                        </div>
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
                {/* row four */}
                {/* <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              ></Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FileInput onFileChange={handleFileChange} />
              </Grid>
            </Grid> */}

                {/* row three */}
                <Grid container item>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  ></Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction={"row"} gap={4} pt={2}>
                      <ButtonCommon
                        btnClicked={() => {
                          setOpenDialog(false);
                        }}
                      >
                        Cancel
                      </ButtonCommon>
                      <ButtonCommon type="submit">Save</ButtonCommon>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Stack>
        </Box>
      </Dialog>

      <Dialog open={openNotes} onClose={handleCloseNotes}>
        <Box
          p={4}
          sx={{
            minHeight: "200px",
            minWidth: "400px",
          }}
        >
          <Typography variant="h4" mb={2}>
            Note
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: notesData }}></div>
        </Box>
      </Dialog>

      <Dialog open={openDocs} onClose={handleCloseDocs}>
        <Box sx={{ width: "500px" }}>
          {docsData?.includes(".jpg") ||
          docsData?.includes(".jpeg") ||
          docsData?.includes(".png") ? (
            <Stack>
              <img
                src={`${process.env.REACT_APP_FILE_UPLOADS}/${docsData}`}
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "cover",
                }}
                alt="could not show image"
              ></img>
              <ButtonCommon
                btnClicked={() => {
                  const link = document.createElement("a");
                  link.href = `${process.env.REACT_APP_FILE_UPLOADS}/${docsData}`;
                  link.target = "_blank";
                  link.download = docsData;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                Download
              </ButtonCommon>
            </Stack>
          ) : (
            <iframe
              src={`${process.env.REACT_APP_FILE_UPLOADS}/${docsData}`}
              width={"100%"}
              height={500}
              style={{
                objectFit: "cover",
              }}
            ></iframe>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default AllNotesTable;

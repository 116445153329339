import React, { useState } from "react";

// assets

// mui icons
import Visibility from "@mui/icons-material/Visibility";
import { InputAdornment, TextField } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const PasswordInput = ({
  name,
  placeholder,
  onChangeValue,
  onBlurValue,
  value,
}) => {
  const [showPassword, setShowpPassword] = useState(false);

  const passwordToggle = () => {
    setShowpPassword(!showPassword);
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      name={name}
      placeholder={placeholder}
      fullWidth
      color="black"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOpenIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={passwordToggle}
            sx={{ cursor: "pointer" }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        ),
      }}
      onChange={onChangeValue}
      onBlur={onBlurValue}
      value={value}
      sx={{
        "& .MuiInputBase-root": {
          height: "45px",
        },
      }}
    />
  );
};

export default PasswordInput;

import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Typography, IconButton } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileInput({ onFileChange, clearFile }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    onFileChange(filesArray); // Call the callback function with the selected files
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    onFileChange(updatedFiles); // Update the parent component with the remaining files

    // Reset input if no files are left
    if (updatedFiles.length === 0 && inputRef.current) {
      inputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (clearFile) {
      setSelectedFiles([]);
      onFileChange([]);
      if (inputRef.current) {
        inputRef.current.value = null; // Reset the input value
      }
    }
  }, [clearFile, onFileChange]);

  return (
    <Box>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        color="shamrock"
        sx={{
          color: "white",
        }}
      >
        Choose file
        <VisuallyHiddenInput
          type="file"
          multiple
          accept=".pdf, .doc, .docx, .xlsx, .xls, .png, .jpg, .jpeg, .txt, .rtf"
          onChange={handleFileChange}
          ref={inputRef}
        />
      </Button>
      {selectedFiles.length > 0 && (
        <Box mt={2}>
          {selectedFiles.map((file, index) => (
            <Box key={index} display="flex" alignItems="center" mt={1}>
              <Typography variant="body1" mr={2}>
                {file.name}
              </Typography>
              <IconButton onClick={() => handleRemoveFile(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
  TableSortLabel,
  IconButton,
  Dialog,
  Box,
  Button,
  Stack,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Collapse,
} from "@mui/material";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { DELETE, GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { toast } from "react-toastify";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../shared/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import ButtonCommon from "../../shared/ButtonCommon";
import { storeCaseDetails } from "../../slices/caseDetailSlice";
import { storeEditCase } from "../../slices/editCaseSlice";
import SortIcon from "@mui/icons-material/Sort";

const headerConfig = [
  { id: "actions", label: "Actions", sortable: false },
  { id: "CaseName", label: "Case Name", sortable: true },
  { id: "CaseNumber", label: "Case Number", sortable: true },
  { id: "DocketIndexNumber", label: "Docket Number", sortable: true },
  { id: "VoucherNumber", label: "Voucher Number", sortable: true },
  { id: "AssignedToUser", label: "Assigned to", sortable: true },
  { id: "Status", label: "Status", sortable: true },
  { id: "CaseType", label: "CaseType", sortable: true },
  { id: "ReceivedDate", label: "Received Date", sortable: true },
  { id: "Court", label: "Court", sortable: true },
  { id: "Judge", label: "Judge", sortable: true },
  { id: "AdjournedDate", label: "Adjourned Date", sortable: true },
  { id: "Billed", label: "Billed", sortable: true },
  { id: "CreatedAt", label: "Created At", sortable: true },
  { id: "UpdatedAt", label: "Updated At", sortable: true },
  { id: "ModifiedByUser", label: "ModifiedByUser", sortable: true },
  { id: "Comments", label: "Comments", sortable: true },
];

const sortingData = [
  {
    label: "Case Name",
    value: "CaseName",
  },
  {
    label: "Case Type",
    value: "CaseType",
  },
  {
    label: "Created Date",
    value: "CreatedAt",
  },
  {
    label: "Updated Date",
    value: "UpdatedAt",
  },
  {
    label: "Received Date",
    value: "ReceivedDate",
  },
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    // if (page > 1) {
    onPageChange(event, page - 1);
    // }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

function MyOpenCaseTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [openComment, setOpenComment] = useState(false);
  const [comntData, setComntData] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortData, setSortData] = useState("CreatedAt");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [openFilter, setOpenFilter] = useState(false);

  console.log("data ===============", data);
  console.log("selected ==========", selected);

  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const permissionsIdArray = userDetails?.permission?.map((item) => {
    return item.permission_id;
  });

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [sortData, sortOrder]);

  const fetchData = async () => {
    setLoading(true);
    GET(
      `case/get-cases?pageNumber=${page + 1}&pageSize=${rowsPerPage}&user=${
        userDetails?.id
      }&status=${"open"}&search=${""}&sortBy=${sortData}&order=${sortOrder}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        console.log("decryptedData =>", decryptedData);
        setData(decryptedData?.cases);
        setTotalRows(decryptedData?.totalData);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.CaseID);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    event.stopPropagation(); // Prevent row click from affecting checkbox selection
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseComment = () => {
    setOpenComment(false);
  };

  // Debounce function
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  // Debounced search function using useCallback
  const getSearchValue = useCallback(
    debounce(async (value) => {
      setLoading(true);
      try {
        const response = await GET(
          `case/get-cases?pageNumber=${1}&pageSize=${rowsPerPage}&user=${
            userDetails?.id
          }&status=${"open"}&search=${value}&casetype=${""}&ReadyToAssign=${""}&sortBy=${sortData}&order=${sortOrder}`
        );
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        console.log("decryptedData =>", decryptedData);
        setData(decryptedData?.cases);
        setTotalRows(decryptedData?.totalData);
        setPage(0);
      } catch (err) {
        console.warn(err);
      } finally {
        setLoading(false);
      }
    }, 500), // 500ms debounce delay
    [rowsPerPage, userDetails, sortData, sortOrder] // Dependency array
  );

  const deleteBtnHandler = () => {
    const deleteData = {
      ids: selected,
    };

    console.log("data to be encrypted =>", deleteData);

    const encryptData = EncryptionHelper.encryptData(deleteData);

    DELETE(`case/delete-case`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
        // setToggleClearRows(!toggledClearRows);
        fetchData();
        setSelected([]);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleRadioClicked = (e) => {
    setSortData(e.target.value);
  };

  const switchHandler = (e) => {
    if (e.target.checked) {
      setSortOrder("DESC");
    } else {
      setSortOrder("ASC");
    }
  };

  const openFilterHandler = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <div>
      <Stack direction="row" justifyContent={"space-between"} mb={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={2}
        >
          {permissionsIdArray?.includes(20) && (
            <ButtonCommon
              btnClicked={() => {
                navigate("/addcase");
              }}
            >
              Add new item
            </ButtonCommon>
          )}

          {selected?.length > 0 && (
            <Button
              variant="contained"
              color="red"
              sx={{
                color: "white",
              }}
              onClick={deleteBtnHandler}
            >
              Delete
            </Button>
          )}
        </Stack>

        <Stack sx={{ width: "30%" }} flexDirection={"row"} gap={2}>
          <SortIcon
            onClick={openFilterHandler}
            fontSize="large"
            sx={{ cursor: "pointer" }}
          />

          <SearchBar
            searchHeight={"40px"}
            placeholder={"Search for case"}
            searchValue={getSearchValue}
          />
        </Stack>
      </Stack>

      <Collapse in={openFilter}>
        <Stack
          flexDirection={"row"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          m={2}
        >
          <FormControl>
            {/* <FormLabel>Sort by</FormLabel> */}
            <RadioGroup row value={sortData} onChange={handleRadioClicked}>
              {sortingData?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Stack
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"center"}
            p={0.5}
          >
            Ascending <Switch size="small" onChange={switchHandler} />
            Descending
          </Stack>
        </Stack>
      </Collapse>

      {loading ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {sortedData?.length > 0 ? (
            <>
              {" "}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 && selected.length < data.length
                          }
                          checked={
                            data.length > 0 && selected.length === data.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>

                      {headerConfig.map((header) => (
                        <TableCell key={header.id}>
                          {header.sortable ? (
                            <TableSortLabel
                              active={orderBy === header.id}
                              direction={orderBy === header.id ? order : "asc"}
                              onClick={() => handleRequestSort(header.id)}
                            >
                              {header.label}
                            </TableSortLabel>
                          ) : (
                            header.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row) => {
                      const isItemSelected =
                        selected.indexOf(row.CaseID) !== -1;
                      return (
                        <TableRow
                          key={row.CaseID}
                          role="checkbox"
                          selected={isItemSelected}
                          onClick={(event) => {
                            // Prevent row click from affecting checkbox selection
                            event.stopPropagation();
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, row.CaseID)
                              }
                              // Prevent checkbox click from triggering the row click event
                              onClick={(event) => event.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <EditIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(storeEditCase(row));
                                navigate("/editcase");
                              }}
                            />
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              dispatch(storeCaseDetails(row));
                              dispatch(storeEditCase(row));
                              navigate(`/casedetails?CID=${row.CaseID}`);
                            }}
                            sx={{ cursor: "pointer", color: "#01997D" }}
                          >
                            {row.CaseName}
                          </TableCell>
                          <TableCell>{row.CaseNumber}</TableCell>
                          <TableCell>{row.DocketIndexNumber}</TableCell>
                          <TableCell>{row.VoucherNumber}</TableCell>
                          <TableCell>{row.AssignedToUser}</TableCell>
                          <TableCell>{row.Status}</TableCell>
                          <TableCell>{row.CaseType}</TableCell>
                          <TableCell>
                            {row.ReceivedDate
                              ? moment(row.ReceivedDate).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>{row.Court}</TableCell>
                          <TableCell>{row.Judge}</TableCell>
                          <TableCell>
                            {row.AdjournedDate
                              ? moment(row.AdjournedDate).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>
                            {row.Billed === 1 ? "Yes" : "No"}
                          </TableCell>
                          <TableCell>
                            {row.CreatedAt
                              ? moment(row.CreatedAt).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>
                            {row.UpdatedAt
                              ? moment(row.UpdatedAt).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>{row.ModifiedByUser}</TableCell>

                          <TableCell>
                            {row.Comments && (
                              <PreviewIcon
                                onClick={() => {
                                  setOpenComment(true);
                                  setComntData(row.Comments);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} height={200}>
              No cases found
            </Stack>
          )}
        </>
      )}

      <Dialog open={openComment} onClose={handleCloseComment}>
        <Box p={4}>
          <div dangerouslySetInnerHTML={{ __html: comntData }}></div>
        </Box>
      </Dialog>
    </div>
  );
}

export default MyOpenCaseTable;

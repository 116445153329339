import { Button, Stack, Typography } from "@mui/material";
import React from "react";

const BackToLogin = ({ backtoLoginHandler }) => {
  return (
    <Stack width="80%" gap={4}>
      <Typography
        sx={{
          color: "#171725",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "37px",
          letterSpacing: "0.2px",
          textAlign: "center",
        }}
      >
        Your Password Is Recovered
      </Typography>

      <Button variant="contained" onClick={backtoLoginHandler}>
        Back to log In
      </Button>
    </Stack>
  );
};

export default BackToLogin;

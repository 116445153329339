import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const TextAreaInput = ({
  name,
  placeholder,
  onChangeValue,
  onBlurValue,
  value,
  startIcon = false,
  helperText,
  rows = 4,
}) => {
  return (
    <TextField
      type={"text"}
      name={name}
      placeholder={placeholder}
      fullWidth
      multiline
      rows={rows}
      color="black"
      // maxRows={10}
      helperText={helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {startIcon && <PersonOutlineIcon />}
          </InputAdornment>
        ),
      }}
      onChange={onChangeValue}
      onBlur={onBlurValue}
      value={value}
      //   sx={{
      //     "& .MuiInputBase-root": {
      //       height: "45px",
      //     },
      //   }}
    />
  );
};

export default TextAreaInput;

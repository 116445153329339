import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#3DCBB1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFD130",
    },
    shamrock: {
      main: "#3DCBB1",
    },
    black: {
      main: "#ffffff", // Adjusted for dark background contrast
    },
    red: {
      main: "#C42A22",
    },
    background: {
      default: "#121212", // Dark background
      paper: "#1D1D1D", // Slightly lighter for paper components
    },
    text: {
      primary: "#ffffff", // White text for dark backgrounds
      secondary: "#B0B0B0", // Lighter gray for secondary text
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1D1D1D", // Paper background color
          color: "#ffffff", // Text color
        },
      },
    },
  },
});

export default darkTheme;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  caseDetails: {},
};

export const caseDetailsSlice = createSlice({
  name: "caseDetails",
  initialState,
  reducers: {
    storeCaseDetails: (state, action) => {
      state.caseDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeCaseDetails } = caseDetailsSlice.actions;

export default caseDetailsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editOrder: {},
};

export const editOrderSlice = createSlice({
  name: "editdar",
  initialState,
  reducers: {
    storeEditOrder: (state, action) => {
      state.editOrder = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeEditOrder } = editOrderSlice.actions;

export default editOrderSlice.reducer;

import React from "react";

// assets

// mui icons
import { InputAdornment, TextField } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useSelector } from "react-redux";

const TextInput = ({
  name,
  placeholder,
  onChangeValue,
  onBlurValue,
  value,
  startIcon = false,
  helperText,
  disabled = false,
  variant = "outlined",
}) => {
  const themeColor = useSelector((state) => state.themeColorReducer.lightColor);

  return (
    <TextField
      type={"text"}
      disabled={disabled}
      name={name}
      variant={variant}
      placeholder={placeholder}
      fullWidth
      helperText={helperText}
      color="black"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {startIcon && <PersonOutlineIcon />}
          </InputAdornment>
        ),
      }}
      onChange={onChangeValue}
      onBlur={onBlurValue}
      value={value}
      sx={{
        "& .MuiInputBase-root": {
          height: "35px",
        },
        "& .MuiInputBase-input": {
          padding: "6px 20px",
          paddingLeft: 0,
        },
        "& .Mui-disabled": {
          background: themeColor ? "rgb(45, 45, 50)" : "rgb(233,236,239)",
        },
      }}
    />
  );
};

export default TextInput;

import React from "react";
import { Box, Grid, Typography, Stack, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// assets
import logo from "../../assets/logo.png";
import unsplash from "../../assets/loginpage/unsplash.png";
import office365 from "../../assets/office365Logo.svg";

// mui icons
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../shared/PasswordInput";
import { POST } from "../../api/ApiService";
import EmailInput from "../../shared/EmailInput";
import TextInput from "../../shared/TextInput";
import EncryptionHelper from "../../shared/EncryptionHelper";

const Signup = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const loginClickHandler = () => {
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim() // Remove leading and trailing whitespace
        .matches(
          /^\S.*$/,
          "Name must not be empty or contain only blank spaces"
        ) // Ensure at least one non-space character
        .required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Password is required"),
      repeatPassword: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      const postData = {
        username: values.name,
        email: values.email,
        password: values.password,
        repeatPassword: values.repeatPassword,
      };

      console.log("data to be encrypted =>", postData);

      const encryptData = EncryptionHelper.encryptData(postData);

      POST("signup", { encryptData }, false)
        .then((resp) => {
          toast.success(resp?.message);
          navigate("/login");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          {!belowTablet && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: "100vh",
                height: "100%",
                backgroundImage: `url(${unsplash})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <Box sx={{ width: "70%", margin: "auto" }}>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "32px",
                    fontWeight: "500",
                    lineHeight: "44px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                  }}
                >
                  The only way to do great work is to love what you do.
                </Typography>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "36px",
                    letterSpacing: "-0.02em",
                    textAlign: "right",
                  }}
                >
                  - Steve Jobs{" "}
                </Typography>
              </Box>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ minHeight: "100vh", height: "100%" }}>
            <Stack alignItems={"center"} justifyContent={"center"}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "220px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              ></img>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={2}
                width="55%"
                m="auto"
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "45px",
                    letterSpacing: "0.10000000149011612px",
                    textAlign: "center",
                    cursor: "default",
                  }}
                >
                  Sign Up
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <TextInput
                    name="name"
                    placeholder="Your name"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.name}
                    startIcon={true}
                  />

                  {formik.touched.name && formik.errors.name ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.name}
                    </div>
                  ) : null}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <EmailInput
                    name="email"
                    placeholder="Your email"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <PasswordInput
                    name="password"
                    placeholder="Password"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.password}
                    </div>
                  ) : null}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <PasswordInput
                    name="repeatPassword"
                    placeholder="Repeat Password"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.repeatPassword}
                  />
                  {formik.touched.repeatPassword &&
                  formik.errors.repeatPassword ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.repeatPassword}
                    </div>
                  ) : null}
                  {/* <span>heloo</span> */}
                </Box>{" "}
                <Button type="submit" variant="contained" fullWidth>
                  Sign Up
                </Button>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#969AB8",
                      cursor: "default",
                    }}
                  >
                    or
                  </Typography>
                </Stack>
                <Button
                  sx={{
                    padding: "8px 20px 8px 20px",
                    borderRadius: "8px",
                    gap: "15px",
                    border: "1.6px solid #E0E2E9",
                    width: "100%",
                  }}
                >
                  <img src={office365} alt=""></img>
                </Button>
                <Stack
                  direction="row"
                  gap={1}
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#969AB8",
                      cursor: "default",
                    }}
                  >
                    Already have an account?{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      letterSpacing: "0.10000000149011612px",
                      textAlign: "center",
                      color: "#0062FF",
                      cursor: "pointer",
                    }}
                    onClick={loginClickHandler}
                  >
                    Log In{" "}
                  </Typography>
                </Stack>
              </Stack>
            </form>
          </Box>{" "}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;

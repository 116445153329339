import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lightColor: false,
};

export const themeSlice = createSlice({
  name: "themeColor",
  initialState,
  reducers: {
    storeThemeColor: (state, action) => {
      state.lightColor = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeThemeColor } = themeSlice.actions;

export default themeSlice.reducer;

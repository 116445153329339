export const statusData = [
  { value: "Admin", label: "Admin" },
  { value: "Admin - Holiday Pay", label: "Admin - Holiday Pay" },
  { value: "Admin - Paid Time Off", label: "Admin - Paid Time Off" },
  { value: "Admin - Training", label: "Admin - Training" },
  { value: "Admin - w/ Prior Approval", label: "Admin - w/ Prior Approval" },
  { value: "Admin/Intern", label: "Admin/Intern" },
  {
    value: "Cancellation/Minimum charge fe",
    label: "Cancellation/Minimum charge fe",
  },
  { value: "Court Appearance", label: "Court Appearance" },
  {
    value: "Court Appearance w/Testimony",
    label: "Court Appearance w/Testimony",
  },
  { value: "Court Conference", label: "Court Conference" },
  {
    value: "Crt Appearance; Conf w Parties",
    label: "Crt Appearance; Conf w Parties",
  },
  { value: "Drug/Alcohol Testing", label: "Drug/Alcohol Testing" },
  { value: "Family Therapy (Bx)", label: "Family Therapy (Bx)" },
  { value: "Family Therapy (GC)", label: "Family Therapy (GC)" },
  { value: "Family Therapy (Harlem)", label: "Family Therapy (Harlem)" },
  { value: "Family Therapy (NSF)", label: "Family Therapy (NSF)" },
  { value: "Family Therapy (NSM)", label: "Family Therapy (NSM)" },
  { value: "Family Therapy (Qns)", label: "Family Therapy (Qns)" },
  { value: "Family Therapy (SI)", label: "Family Therapy (SI)" },
  {
    value: "Facility Visit & Mtg w/Client",
    label: "Facility Visit & Mtg w/Client",
  },
  { value: "Home Visit & Int Child", label: "Home Visit & Int Child" },
  { value: "Home Visit & Int Client", label: "Home Visit & Int Client" },
  { value: "Home Visit & Int Father", label: "Home Visit & Int Father" },
  { value: "Home Visit & Int Mother", label: "Home Visit & Int Mother" },
  {
    value: "Home Visit & Obsrv Prnt/Child",
    label: "Home Visit & Obsrv Prnt/Child",
  },
  { value: "Home Visit (Bk)", label: "Home Visit (Bk)" },
  { value: "Home Visit (Bx)", label: "Home Visit (Bx)" },
  { value: "Home Visit (Manh)", label: "Home Visit (Manh)" },
  { value: "Home Visit (Out of State)", label: "Home Visit (Out of State)" },
  { value: "Home Visit (Qns)", label: "Home Visit (Qns)" },
  { value: "Home Visit (SI)", label: "Home Visit (SI)" },
  { value: "Home Visit NSF", label: "Home Visit NSF" },
  { value: "Home Visit NSM", label: "Home Visit NSM" },
  { value: "Home Visit/Travel", label: "Home Visit/Travel" },
  { value: "Intake NSF", label: "Intake NSF" },
  { value: "Intake NSM", label: "Intake NSM" },
  { value: "Intake (NSC)", label: "Intake (NSC)" },
  { value: "Intake w/ Collateral", label: "Intake w/ Collateral" },
  { value: "Intake w/ Collateral (NS)", label: "Intake w/ Collateral (NS)" },
  { value: "Intake w/Custodian", label: "Intake w/Custodian" },
  { value: "Intake with Child", label: "Intake with Child" },
  { value: "Intake with Father", label: "Intake with Father" },
  { value: "Intake with Mother", label: "Intake with Mother" },
  { value: "Intake With Children", label: "Intake With Children" },
  { value: "Interview Child", label: "Interview Child" },
  { value: "Interview Child NS", label: "Interview Child NS" },
  { value: "Interview Collateral", label: "Interview Collateral" },
  { value: "Interview Collateral (NS)", label: "Interview Collateral (NS)" },
  { value: "Interview Father", label: "Interview Father" },
  { value: "Interview Father NS", label: "Interview Father NS" },
  { value: "Interview Mother", label: "Interview Mother" },
  { value: "Interview Mother NS", label: "Interview Mother NS" },
  { value: "Interview Parent and Child", label: "Interview Parent and Child" },
  { value: "Meeting w/ AFC & Child", label: "Meeting w/ AFC & Child" },
  { value: "Meeting w/ Attorney", label: "Meeting w/ Attorney" },
  { value: "Meeting w/ Child", label: "Meeting w/ Child" },
  { value: "Meeting w/ Child NS", label: "Meeting w/ Child NS" },
  { value: "Meeting w/ Client", label: "Meeting w/ Client" },
  { value: "Meeting w/ Client NS", label: "Meeting w/ Client NS" },
  { value: "Meeting w/ Collateral", label: "Meeting w/ Collateral" },
  { value: "Meeting w/ Collateral (NS)", label: "Meeting w/ Collateral (NS)" },
  { value: "Meeting w/ Father", label: "Meeting w/ Father" },
  { value: "Meeting w/ Father & Child", label: "Meeting w/ Father & Child" },
  { value: "Meeting w/ Father NS", label: "Meeting w/ Father NS" },
  { value: "Meeting w/ Law Guardian", label: "Meeting w/ Law Guardian" },
  { value: "Meeting w/ Mother", label: "Meeting w/ Mother" },
  { value: "Meeting w/ Mother & Child", label: "Meeting w/ Mother & Child" },
  { value: "Meeting w/ Mother NS", label: "Meeting w/ Mother NS" },
  { value: "Meeting w/ Parent & Child", label: "Meeting w/ Parent & Child" },
  { value: "Meeting w/ Parents", label: "Meeting w/ Parents" },
  { value: "Meeting w/ Prnt&Child (Bk)", label: "Meeting w/ Prnt&Child (Bk)" },
  {
    value: "Meeting w/ Prnt&Child (Manh)",
    label: "Meeting w/ Prnt&Child (Manh)",
  },
  {
    value: "Meeting w/ Prnt&Child (Qns)",
    label: "Meeting w/ Prnt&Child (Qns)",
  },
  { value: "Meeting w/Prnt&Child (SI)", label: "Meeting w/Prnt&Child (SI)" },
  {
    value: "Meeting with Prnt/Child (Bx)",
    label: "Meeting with Prnt/Child (Bx)",
  },
  { value: "Monitor Visitation", label: "Monitor Visitation" },
  { value: "Monitor Visitation NSC", label: "Monitor Visitation NSC" },
  { value: "Monitor Visitation NSF", label: "Monitor Visitation NSF" },
  { value: "Monitor Visitation NSM", label: "Monitor Visitation NSM" },
  { value: "Monitor Visitation/Travel", label: "Monitor Visitation/Travel" },
  { value: "Mtg Prnt&Child NSCR", label: "Mtg Prnt&Child NSCR" },
  { value: "Nanny Supervision Service", label: "Nanny Supervision Service" },
  { value: "Obsrv Prnt/Child Int (Bk)", label: "Obsrv Prnt/Child Int (Bk)" },
  { value: "Obsrv Prnt/Child Int (Bx)", label: "Obsrv Prnt/Child Int (Bx)" },
  { value: "Obsrv Prnt/Child Int (GC)", label: "Obsrv Prnt/Child Int (GC)" },
  {
    value: "Obsrv Prnt/Child Int (Harlem)",
    label: "Obsrv Prnt/Child Int (Harlem)",
  },
  { value: "Obsrv Prnt/Child Int (NSF)", label: "Obsrv Prnt/Child Int (NSF)" },
  { value: "Obsrv Prnt/Child Int (NSM)", label: "Obsrv Prnt/Child Int (NSM)" },
  { value: "Obsrv Prnt/Child Int (Qns)", label: "Obsrv Prnt/Child Int (Qns)" },
  { value: "Obsrv Prnt/Child Int (SI)", label: "Obsrv Prnt/Child Int (SI)" },
  {
    value: "Obsrv Prnt/Child Int (NS Other)",
    label: "Obsrv Prnt/Child Int (NS Other)",
  },
  { value: "Obsrv Prnt/Child Inter/NSC", label: "Obsrv Prnt/Child Inter/NSC" },
  {
    value: "Observe Interaction (Child/Siblings)",
    label: "Observe Interaction (Child/Siblings)",
  },
  { value: "Obsv Prnt/Child Visit", label: "Obsv Prnt/Child Visit" },
  {
    value: "Observation Client w/Parent",
    label: "Observation Client w/Parent",
  },
  { value: "Parent Intake NS", label: "Parent Intake NS" },
  { value: "Parent Intake", label: "Parent Intake" },
  { value: "School Conference", label: "School Conference" },
  { value: "School Meeting", label: "School Meeting" },
  { value: "School Visit & Interview", label: "School Visit & Interview" },
  { value: "School Visit (NS)", label: "School Visit (NS)" },
  { value: "Transport Child", label: "Transport Child" },
  { value: "Transport Child NSC", label: "Transport Child NSC" },
  {
    value: "Travel Time - Court Appearance",
    label: "Travel Time - Court Appearance",
  },
  {
    value: "Travel Time - Court Conference",
    label: "Travel Time - Court Conference",
  },
  { value: "Visit w/ Parents", label: "Visit w/ Parents" },
];

import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ searchHeight, searchValue, placeholder }) => {
  return (
    <TextField
      color="black"
      placeholder={placeholder}
      fullWidth  
      sx={{
        "& .MuiInputBase-root": {
          height: searchHeight,
        },
      }}
      onChange={(e) => {
        searchValue(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;

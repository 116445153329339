import { Box } from "@mui/material";
import React from "react";
import MainHeader from "../../shared/MainHeader";
// import CasesTable from "../../shared/CasesTable";
// import moment from "moment";
import ClosedCaseTable from "../../components/ClosedCasetable";

// const columnsData = [
//   {
//     name: <div>Assigned to</div>,
//     selector: (row) => row.AssignedToUser,
//     sortable: true,
//   },
//   {
//     name: <div>Received Date</div>,
//     selector: (row) =>
//       row.ReceivedDate ? moment(row.ReceivedDate).format("LLL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Court</div>,
//     selector: (row) => row.Court,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Judge</div>,
//     selector: (row) => row.Judge,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Adjourned Date</div>,
//     selector: (row) =>
//       row.AdjournedDate ? moment(row.AdjournedDate).format("LLL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   // {
//   //   name: <div>Adjourned Time</div>,
//   //   selector: (row) =>
//   //     row.AdjournedTime
//   //       ? moment(row.AdjournedTime, "HH:mm:ss").format("LTS")
//   //       : "null",

//   //   sortable: true,
//   //   wrap: true,
//   //   grow: 2,
//   // },
//   // {
//   //   name: <div>Appearance</div>,
//   //   selector: (row) => row.Appearance,
//   //   sortable: true,
//   //   wrap: true,
//   //   grow: 2,
//   // },
//   {
//     name: <div>Report</div>,
//     selector: (row) => row.Report,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Billed</div>,
//     selector: (row) => (row.Billed === 1 ? "Yes" : "No"),
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Updated at</div>,
//     selector: (row) =>
//       row.UpdatedAt ? moment(row.UpdatedAt).format("LLL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Modified By</div>,
//     selector: (row) => row.ModifiedByUser,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Ready to Assign</div>,
//     selector: (row) => (row.ReadyToAssign === 1 ? "true" : "false"),
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   // {
//   //   name: <div>Needs Correct Order</div>,
//   //   selector: (row) => (row.NeedsCorrectOrder === 1 ? "Yes" : "No"),
//   //   sortable: true,
//   //   wrap: true,
//   //   // grow: 2,
//   // },
//   {
//     name: <div>Case Type</div>,
//     selector: (row) => row.CaseType,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Hours Limit</div>,
//     selector: (row) => row.HoursLimit,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Preferred Availability</div>,
//     selector: (row) => row.PreferredAvailability,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
// ];

const OpenCases = () => {
  return (
    <Box>
      <MainHeader headingText={"Closed Cases"} />
      {/* <CasesTable columnsData={columnsData} status={"closed"} caseType={""} /> */}
      <ClosedCaseTable />
    </Box>
  );
};

export default OpenCases;

import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const AutocompleteInput = ({
  id,
  name,
  options = [],
  value,
  onChange,
  onInputChange,
  onBlur,
  error,
  helperText,
}) => {
  return (
    <Autocomplete
      id={id}
      name={name}
      options={options}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      sx={{
        "& .MuiOutlinedInput-root": {
          padding: 0,
          paddingLeft: "9px",
        },

        "& .MuiAutocomplete-root": {
          background: "blue",
        },
      }}
      renderInput={(params) => (
        <TextField
          color="black"
          {...params}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default AutocompleteInput;

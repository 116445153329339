import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// assets
import { POST } from "../../api/ApiService";
import EmailInput from "../../shared/EmailInput";
import { useNavigate } from "react-router-dom";
import EncryptionHelper from "../../shared/EncryptionHelper";

const SendOtp = ({ sendOtpHandler }) => {
  const navigate = useNavigate();
  const [sendOtp, setSendOtp] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      setSendOtp(true);
      const encryptData = EncryptionHelper.encryptData({
        email: values.email,
      });

      POST(`send-otp`, { encryptData })
        .then((resp) => {
          localStorage.setItem("passResetEmail", values.email);
          toast.success(resp?.message);
          setTimeout(() => {
            setSendOtp(false);
          }, 1000);
          sendOtpHandler();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setTimeout(() => {
            setSendOtp(false);
          }, 1000);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={4}
        width="80%"
        m="auto"
      >
        <Box sx={{ width: "100%" }}>
          <EmailInput
            name="email"
            placeholder="Your Email"
            onChangeValue={formik.handleChange}
            onBlurValue={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div
              style={{
                fontSize: "smaller",
                color: "red",
                textAlign: "left",
              }}
            >
              {formik.errors.email}
            </div>
          ) : null}{" "}
        </Box>

        <Button type="submit" variant="contained" fullWidth disabled={sendOtp}>
          Send OTP
        </Button>

        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            navigate("/login");
          }}
        >
          Go back to login
        </Button>
      </Stack>
    </form>
  );
};

export default SendOtp;

import { Button } from "@mui/material";
import React from "react";
import btnIcon from "../../assets/btnStartIcon.png";

const ButtonCommon = ({
  children,
  btnSize = "medium",
  type,
  btnClicked,
  startIcon = true,
}) => {
  return (
    <Button
      type={type}
      variant="contained"
      size={btnSize}
      startIcon={startIcon ? <img src={btnIcon} alt="" /> : ""}
      sx={{
        background: children === "Cancel" ? "#C42A22" : "#3DCBB1",
        "&:hover": {
          background: children === "Cancel" ? "#A0231C" : "#299884",
        },
        color: "white",
        fontSize: "100%",
      }}
      onClick={() => {
        type !== "submit" && btnClicked();
      }}
    >
      {children}
    </Button>
  );
};

export default ButtonCommon;

import { Box } from "@mui/material";
import React from "react";

import MainHeader from "../../shared/MainHeader";
// import CasesTable from "../../shared/CasesTable";
// import moment from "moment";
import CasePageTable from "../../components/CasePageTable/index";

// const columnsData = [
//   {
//     name: <div>Case Type</div>,
//     selector: (row) => row.CaseType,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },

//   {
//     name: <div>Received Date</div>,
//     selector: (row) =>
//       row.ReceivedDate ? moment(row.ReceivedDate).format("LL") : "null",
//     sortable: true,
//     // wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Court</div>,
//     selector: (row) => row.Court,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Judge</div>,
//     selector: (row) => row.Judge,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Adjourned Date</div>,
//     selector: (row) =>
//       row.AdjournedDate ? moment(row.AdjournedDate).format("LL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   // {
//   //   name: <div>Appearance</div>,
//   //   selector: (row) => row.Appearance,
//   //   sortable: true,
//   //   wrap: true,
//   //   grow: 3,
//   // },
//   {
//     name: <div>Billed</div>,
//     selector: (row) => (row.Billed === 1 ? "Yes" : "No"),
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Created at</div>,
//     selector: (row) =>
//       row.CreatedAt ? moment(row.CreatedAt).format("LL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Updated At</div>,
//     selector: (row) =>
//       row.UpdatedAt ? moment(row.UpdatedAt).format("LLL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Modified By</div>,
//     selector: (row) => row.ModifiedByUser,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Service Type</div>,
//     selector: (row) => row.ServiceType,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Report Contact</div>,
//     selector: (row) => row.ReportContactInfo,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
// ];

const CasePage = () => {
  return (
    <Box>
      <MainHeader headingText={"Cases"} />
      {/* <CasesTable columnsData={columnsData} status={"open"} caseType={""} /> */}

      <CasePageTable />
    </Box>
  );
};

export default CasePage;

import React, { useState, useEffect } from "react";
import { GET, POST } from "../../api/ApiService";
import EncryptionHelper from "../EncryptionHelper";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import ButtonCommon from "../ButtonCommon";
import {
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileInput from "../FileInput";
import { toast } from "react-toastify";
// import CaseSearchDropdown from "../../shared/CaseSearchDropdown";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TextInput from "../TextInput";

const headerConfig = [
  { id: "filename", label: "File Name", sortable: false },
  { id: "created by", label: "Created By", sortable: true },
  { id: "created at", label: "Created At", sortable: true },
  { id: "download", label: "Download", sortable: true },
];

const AllAttachments = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cid = searchParams.get("CID");

  const [selectedFiles, setSelectedFiles] = useState();
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const getAllAttachments = () => {
    setLoading(true);
    GET(`case/get-case-attachment/${cid}`)
      .then((resp) => {
        setLoading(false);
        setAttachments(resp.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const getCaseDetails = () => {
    GET(`case/get-case/${cid}`)
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        setCaseDetails(decryptedData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllAttachments();
    getCaseDetails();
  }, []);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const addReportHandler = () => {
    setOpenDialog(true);
  };

  const handleFileChange = (files) => {
    setSelectedFiles(files);
  };

  // mui functions
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = attachments.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: () => {
      // Prepare form data
      const formData = new FormData();
      formData.append("caseId", caseDetails?.CaseID);
      formData.append("formType", "null");
      // formData.append("attachmentPath", selectedFiles);
      // Loop through selectedFiles and append each one to formData
      if (selectedFiles) {
        Array.from(selectedFiles).forEach((file) => {
          formData.append("attachmentPath", file); // Append each file
        });
      }
      formData.append("createdAt", userDetails?.id);

      // Send the PDF to the API
      POST("case/case-attachment", formData, true, true)
        .then((resp) => {
          toast.success(resp?.message);
          getAllAttachments();
          handleDialogClose();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  console.log("sortedData ===>", sortedData);

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <ButtonCommon btnClicked={addReportHandler}>
          Add new attachment
        </ButtonCommon>
      </Stack>

      {loading ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {sortedData?.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headerConfig.map((header) => (
                        <TableCell key={header.id}>
                          {header.sortable ? (
                            <TableSortLabel
                              active={orderBy === header.id}
                              direction={orderBy === header.id ? order : "asc"}
                              onClick={() => handleRequestSort(header.id)}
                            >
                              {header.label}
                            </TableSortLabel>
                          ) : (
                            header.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row) => {
                      return (
                        <TableRow
                          key={row?.id}
                          role="checkbox"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <TableCell>{row?.fileName}</TableCell>
                          <TableCell>{row?.Username}</TableCell>
                          <TableCell
                            sx={{ cursor: "pointer", color: "#01997D" }}
                          >
                            {row.createdAt
                              ? moment(row.createdAt).format("LL")
                              : "null"}
                          </TableCell>
                          <TableCell>
                            {row.fileName && (
                              <DownloadIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = `${process.env.REACT_APP_FILE_UPLOADS}/${row.fileName}`;
                                  link.target = "_blank";
                                  link.download = row.fileName;
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} height={200}>
              No cases found
            </Stack>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
          }}
        >
          <Typography variant="h4">Add Attachment</Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {/* row one */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>Case</Typography>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <TextInput value={caseDetails?.CaseName} disabled={true} />
                  </Stack>
                </Grid>
              </Grid>

              {/* row four */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                ></Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <FileInput onFileChange={handleFileChange} />
                </Grid>
              </Grid>

              {/* row four */}
              <Grid container item>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  {selectedFiles && (
                    <ButtonCommon type="submit">Save</ButtonCommon>
                  )}
                  <ButtonCommon btnClicked={handleDialogClose}>
                    Cancel
                  </ButtonCommon>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Dialog>
    </>
  );
};

export default AllAttachments;

import React, { useState, useRef, useEffect, memo } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Typography, IconButton } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SingleFileInput = ({ onFileChange, clearFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRefSingleFile = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileChange(file); // Call the callback function with the selected file
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onFileChange(null); // Update the parent component with no file

    // Reset input
    if (inputRefSingleFile.current) {
      inputRefSingleFile.current.value = null;
    }
  };

  useEffect(() => {
    if (clearFile) {
      setSelectedFile(null);
      onFileChange(null);
      if (inputRefSingleFile.current) {
        inputRefSingleFile.current.value = null; // Reset the input value
      }
    }
  }, [clearFile, onFileChange]);

  return (
    <Box>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        color="shamrock"
        sx={{
          color: "white",
        }}
      >
        Choose file
        <VisuallyHiddenInput
          type="file"
          accept=".pdf, .doc, .docx, .xlsx, .xls, .png, .jpg, .jpeg, .txt, .rtf"
          onChange={handleFileChange}
          ref={inputRefSingleFile}
        />
      </Button>
      {selectedFile && (
        <Box mt={2}>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body1" mr={2}>
              {selectedFile.name}
            </Typography>
            <IconButton onClick={handleRemoveFile}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(SingleFileInput);

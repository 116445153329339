import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allroles: [],
};

export const allroleSlice = createSlice({
  name: "alluser",
  initialState,
  reducers: {
    storeAllRoles: (state, action) => {
      state.allroles = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeAllRoles } = allroleSlice.actions;

export default allroleSlice.reducer;

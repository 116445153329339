import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";

const CheckboxInput = ({
  name,
  onChangeValue,
  onBlurValue,
  value,
  label,
  helperText,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            onChange={onChangeValue}
            onBlur={onBlurValue}
            checked={value}
            disableRipple={true}
            color="black"
            sx={{
              "&.MuiCheckbox-root": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          />
        }
        label={label}
      />
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: "0.75rem",
          lineHeight: "1.66",
          textAlign: "left",
          marginTop: "3px",
          marginRight: "14px",
          marginBottom: "0",
        }}
      >
        {helperText}
      </Typography>
    </FormGroup>
  );
};

export default CheckboxInput;

import React from "react";
import "./App.css";
import MainRoutes from "./routes/Main.route";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";

import lightTheme from "../src/theme/lightTheme";
import darkTheme from "../src/theme/darkTheme";

function App() {
  const themeColor = useSelector((state) => state.themeColorReducer.lightColor);

  return (
    <ThemeProvider theme={themeColor ? darkTheme : lightTheme}>
      <CssBaseline />
      <div className="App">
        <ToastContainer theme="colored" />
        <MainRoutes />
      </div>
    </ThemeProvider>
  );
}

export default App;

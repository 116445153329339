import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import AutocompleteInput from "../AutocompleteInput";
import ButtonCommon from "../ButtonCommon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import MainHeader from "../MainHeader";
import { PUT } from "../../api/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EncryptionHelper from "../EncryptionHelper";
import NumberInput from "../NumberInput";
import TextAreaInput from "../TextAreaInput";
import SelectInput from "../SelectInput";
import moment from "moment";

const statusData = [
  {
    value: "New",
    label: "New",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Fulfilled",
    label: "Fulfilled",
  },
  {
    value: "Denied",
    label: "Denied",
  },
];

const EditOrderRequest = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const caseDropdown = useSelector((state) => state.allcaseReducer.allcase);
  const editData = useSelector((state) => state.editOrderReducer.editOrder);

  const searchCase = (search) => {
    console.log("search =>", search);
  };

  const formik = useFormik({
    initialValues: {
      case: { value: editData?.CaseID, label: editData?.CaseName },
      estimatedHours: editData?.EstimatedHours,
      comment: editData?.Comment,
      statusRemarks: editData?.StatusRemarks,
      status: editData?.Status,
    },
    validationSchema: Yup.object({
      case: Yup.object().required("Case is required"),
      estimatedHours: Yup.number().required("Hours is required"),
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        Title: "title 4",
        CaseID: values.case?.value,
        Status: values.status,
        EstimatedHours: values.estimatedHours,
        Comment: values.comment,
        StatusRemarks: values.statusRemarks,
        ModifiedByUserID: userDetails?.id,
      };

      console.log("data to be encrypted =>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT(`supplement-oreder/update?id=${editData?.OrderID}`, { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          window.history.back();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <Box>
      <MainHeader headingText="Update Order" />
      <Stack
        p={4}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.92px",
                textAlign: "left",
              }}
            >
              Update Order Request
            </Typography>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              {/* 1  */}
              <Grid item xs={4}>
                <Stack>
                  {" "}
                  <Typography sx={{ textAlign: "left" }}>
                    Case<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <AutocompleteInput
                      name="case"
                      options={caseDropdown}
                      value={formik.values.case}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("case", newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        searchCase(newInputValue);
                      }}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.case && Boolean(formik.errors.case)}
                      // helperText={formik.touched.case && formik.errors.case}
                    />
                    {formik.touched.case && formik.errors.case ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.case}
                      </div>
                    ) : null}{" "}
                  </Stack>
                </Stack>
              </Grid>

              {/* 2 */}
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>Status</Typography>
                  <Stack sx={{ width: "100%" }}>
                    <SelectInput
                      disabled={false}
                      name="status"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.status}
                      dropDownData={statusData}
                      helperText=""
                    />

                    {formik.touched.status && formik.errors.status ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.status}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 3  */}
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Estimated Hours<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <NumberInput
                      name="estimatedHours"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.estimatedHours}
                      startIcon={false}
                      helperText={
                        "Maximum number of hours to bill against (as stated on the court order)"
                      }
                    />
                    {formik.touched.estimatedHours &&
                    formik.errors.estimatedHours ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.estimatedHours}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 4 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>Comment</Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="comment"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.comment}
                      helperText=""
                    />
                    {formik.touched.comment && formik.errors.comment ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.comment}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 5 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Status Remarks
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="statusRemarks"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.statusRemarks}
                      helperText="For admin use"
                    />
                    {formik.touched.statusRemarks &&
                    formik.errors.statusRemarks ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.statusRemarks}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12}>
                Created at &nbsp; {moment(editData?.CreatedAt).format("LL")}{" "}
                &nbsp; by &nbsp;
                {editData?.addedBy} <br />
                Last modified at &nbsp;
                {moment(editData?.UpdatedAt).format("LL")} &nbsp; by &nbsp;
                {editData?.updatedBy}
              </Grid>

              {/* 6 */}
              <Grid item xs={12}>
                <Stack direction={"row"} gap={4}>
                  <ButtonCommon type="submit">Save</ButtonCommon>
                  <ButtonCommon
                    btnClicked={() => {
                      window.history.back();
                    }}
                  >
                    Cancel
                  </ButtonCommon>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(EditOrderRequest);

import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import moment from "moment";

const Audit = () => {
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("auditData ====>", auditData);

  useEffect(() => {
    setLoading(true);
    GET("case/case-audit-log")
      .then((resp) => {
        console.log("resp of audit log api ==>", resp);

        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log(
          "resp of decryptedData api of audit log ==> ",
          decryptedData
        );
        setAuditData(decryptedData);
        setLoading(false);
      })
      .catch((err) => {
        console.warn("error =>", err);
        setLoading(false);
      });
  }, []);

  return (
    <Box>
      <MainHeader headingText={"Audit log"} />
      <Stack
        gap={3}
        p={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        {loading ? (
          <Stack justifyContent={"center"} alignItems={"center"} height={400}>
            <CircularProgress />
          </Stack>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Case Name</TableCell>
                  <TableCell>Case Number</TableCell>
                  <TableCell>Modified By</TableCell>
                  <TableCell>Column Name</TableCell>
                  <TableCell>New Value</TableCell>
                  <TableCell>Previous Value</TableCell>
                  <TableCell>Table Name</TableCell>
                  <TableCell>Time Stamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.CaseName}</TableCell>
                    <TableCell>{item.CaseNumber}</TableCell>
                    <TableCell>{item.ModifiedByUserName}</TableCell>
                    <TableCell>{item.column_name}</TableCell>
                    <TableCell>{item.new_value}</TableCell>
                    <TableCell>{item.previous_value}</TableCell>
                    <TableCell>{item.table_name}</TableCell>
                    <TableCell>
                      {item.timestamp
                        ? moment(item.timestamp).format("LL")
                        : "null"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
};

export default Audit;

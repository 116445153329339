import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import TextInput from "../../TextInput";
import CheckboxInput from "../../CheckboxInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import ButtonCommon from "../../ButtonCommon";
import drawerLogo from "../../../assets/logo.png";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import CloseIcon from "@mui/icons-material/Close";
// import AutocompleteInput from "../../AutocompleteInput";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { POST } from "../../../api/ApiService";
import CaseSearchDropdown from "../../CaseSearchDropdown";

const options = {
  filename: "custodial-form.pdf",
  // default is `save`
  method: "open",
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.LOW,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: "letter",
    // orientation: "landscape",
    // orientation: "portrait",
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    // mimeType: "image/png",
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

// Reusable component for text input with error handling
const FormTextInput = ({ name, label, formik }) => (
  <Stack direction={"row"} alignItems={"center"}>
    <Typography>{label}:</Typography>
    <Box sx={{ width: "400px" }}>
      <TextInput
        name={name}
        variant="standard"
        onChangeValue={formik.handleChange}
        onBlurValue={formik.handleBlur}
        value={formik.values[name]}
        startIcon={false}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div style={{ fontSize: "smaller", color: "red", textAlign: "left" }}>
          {formik.errors[name]}
        </div>
      )}
    </Box>
  </Stack>
);

// Reusable component for checkbox input with error handling
const FormCheckboxInput = ({ name, label, formik }) => (
  <Stack direction={"row"} alignItems={"center"}>
    <CheckboxInput
      name={name}
      onChangeValue={formik.handleChange}
      onBlurValue={formik.handleBlur}
      value={formik.values[name]}
      label={label}
    />
    {formik.touched[name] && formik.errors[name] && (
      <div style={{ fontSize: "smaller", color: "red", textAlign: "left" }}>
        {formik.errors[name]}
      </div>
    )}
  </Stack>
);

const PolicyCheckbox = ({ name, label, formik }) => (
  <Box mb={4}>
    <Typography dangerouslySetInnerHTML={{ __html: label }} />
    <FormCheckboxInput
      name={name}
      label={`I've read and agree to the terms outlined in section ${
        name.split("y")[1]
      }`}
      formik={formik}
    />
  </Box>
);

const CustodialForm = () => {
  // const caseDropdown = useSelector((state) => state.allcaseReducer.allcase);
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [clientSign, setClientSign] = useState();
  const [workerSign, setWorkerSign] = useState();
  const [attachTocase, setAttachTocase] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);

  const clientSigCanvas = useRef({});
  const socialSigCanvas = useRef({});
  const contentRef = useRef(null);

  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const clearClientSignature = () => {
    clientSigCanvas.current.clear();
    setClientSign(null);
  };

  const clearSocialSignature = () => {
    socialSigCanvas.current.clear();
    setWorkerSign(null);
  };

  const saveClientSignature = () => {
    const signatureImage = clientSigCanvas.current.toDataURL();
    // Now you can send `signatureImage` to your API
    setClientSign(signatureImage);
  };

  const saveSocialSignature = () => {
    const signatureImage = socialSigCanvas.current.toDataURL();
    // Now you can send `signatureImage` to your API
    setWorkerSign(signatureImage);
  };

  const handleGeneratePDF = async (caseID) => {
    const content = contentRef.current;
    if (!content) {
      console.error("Content reference is null");
      return;
    }

    try {
      // Get the sections that need to start on new pages
      const sections = content.querySelectorAll(".new-page-section");

      // Initialize jsPDF
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const margin = 20;
      const canvasWidth = pdfWidth - margin * 2;

      console.log(pdfHeight);
      // Loop through sections and add them to PDF
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const canvas = await html2canvas(section, { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        const canvasHeight =
          (section.offsetHeight * canvasWidth) / section.offsetWidth;

        // Add image to PDF
        if (i > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          margin,
          canvasWidth,
          canvasHeight,
          null,
          "FAST"
        );
      }

      const pdfBlob = pdf.output("blob");

      // Prepare form data
      const formData = new FormData();
      formData.append("caseId", caseID);
      formData.append("formType", "custodial parents (spanish)");
      formData.append("attachmentPath", pdfBlob, `_document.pdf`);
      formData.append("createdBy", userDetails?.id);

      // Send the PDF to the API
      POST("case/case-attachment", formData, true, true)
        .then((resp) => {
          toast.success(resp?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const formik = useFormik({
    initialValues: {
      assignedTo: "",
      methodofCommun: "",
      contactInfo: "",
      email: false,
      phoneCalls: false,
      text: false,
      clientName: "",
      clientAddress: "",
      clientPhone: "",
      clientEmail: "",
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
      policy5: false,
      policy6: false,
      policy7: false,
      policy8: false,
      policy9: false,
      policy10: false,
      policy11: false,
      socialWorker: "",
      case: null,
    },
    validationSchema: Yup.object({
      assignedTo: Yup.string().required("Assigned to is required"),
      methodofCommun: Yup.string().required(
        "Method of communication is required"
      ),
      contactInfo: Yup.string().required("Contact information is required"),
      email: Yup.boolean(),
      phoneCalls: Yup.boolean(),
      text: Yup.boolean(),
      clientName: Yup.string().required("Client name is required"),
      clientAddress: Yup.string().required("Client address is required"),
      clientPhone: Yup.string().required("Client phone number is required"),
      clientEmail: Yup.string().email("Invalid email format"),
      case: Yup.object().required("Case is required"),
    }),
    onSubmit: (values) => {
      saveClientSignature();
      saveSocialSignature();

      setTimeout(() => {
        handleGeneratePDF(values?.case?.CaseID);
      }, [2000]);
    },
  });

  return (
    <Box
      sx={{
        padding: "5px",
        height: "250em",
        overflow: "hidden",
      }}
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <div
            ref={contentRef}
            style={{ borderBottom: "1px solid gray", paddingBottom: "100px" }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Servicios Familiares Integrales
                </Typography>
                <Typography variant="subtitle1">
                  Servicios Forenses y Terapéuticos
                </Typography>
                <Typography variant="subtitle2">
                  450 Lexington Ave, 4th Floor, Nueva York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            <br />

            <Stack direction={"row"} justifyContent={"center"}>
              <Typography>
                POLÍTICAS DE VISITACIÓN DE SERVICIOS FAMILIARES INTEGRALES PARA
                PADRES CUSTODIOS
              </Typography>
            </Stack>
            <br />
            <br />
            <Typography>
              Comprehensive Family Services (CFS) es una agencia privada que
              ofrece servicios de Trabajadores Sociales Licenciados y otros
              profesionales. Los servicios de CFS son designados/dirigidos por
              los Tribunales y también pueden ser contratados de forma privada.
            </Typography>
            <br />
            <Typography>Fecha: {new Date().toString()}</Typography>
            {/* el caso está asignado a */}
            <FormTextInput
              name="assignedTo"
              label="Su caso está asignado a"
              formik={formik}
            />
            <br />
            {/* método de comunicación */}
            <FormControl>
              <Typography id="demo-controlled-radio-buttons-group">
                El método de comunicación más eficiente de su trabajador social
                es:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="methodofCommun"
                value={formik.values.methodofCommun}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="Phone/Text"
                  control={<Radio color="black" />}
                  label="Teléfono/Mensaje de texto"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio color="black" />}
                  label="Correo Electrónico"
                />
              </RadioGroup>
              {formik.touched.methodofCommun &&
                formik.errors.methodofCommun && (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.methodofCommun}
                  </div>
                )}
            </FormControl>
            {/* información de contacto del trabajador social */}
            <FormTextInput
              name="contactInfo"
              label="La información de contacto de su trabajador social es"
              formik={formik}
            />
            <br />
            {/* información de contacto y método de comunicación preferido */}
            <Stack>
              <Typography>
                Indique su información de contacto y el método de comunicación
                preferido marcando tantos como sea necesario:
              </Typography>
              <FormCheckboxInput
                name="email"
                label="Correo Electrónico"
                formik={formik}
              />
              <FormCheckboxInput
                name="phoneCalls"
                label="Llamadas Telefónicas"
                formik={formik}
              />
              <FormCheckboxInput
                name="text"
                label="Mensaje de texto"
                formik={formik}
              />
            </Stack>
            <br />
            <FormTextInput
              name="clientName"
              label="Nombre del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientAddress"
              label="Dirección del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientPhone"
              label="Teléfono del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientEmail"
              label="Correo Electrónico del Cliente"
              formik={formik}
            />

            <br />

            <Typography variant="h5">POLÍTICAS</Typography>

            <br />

            {/* política 1 */}
            <PolicyCheckbox
              name="policy1"
              label="<b> 1. Sustitución: </b> </br> En caso de que su trabajador social no esté disponible, su trabajador social puede utilizar la asistencia de otro asociado de CFS. Cuando y si esto ocurre, se le proporcionará la información de contacto del trabajador social delegado."
              formik={formik}
            />

            {/* política 2 */}
            <PolicyCheckbox
              name="policy2"
              label="<b> 2. Confidencialidad: </b> </br> El papel de CFS es el de una parte neutral. Esto implica potencialmente la divulgación de toda la información relevante recopilada y observada a lo largo de nuestra participación con su familia al Tribunal."
              formik={formik}
            />

            {/* política 3 */}
            <PolicyCheckbox
              name="policy3"
              label="<b>3. Puntualidad:</b> <br /> Se debe hacer todo lo posible para llegar a la hora programada/instruida. Si las partes cumplen con las directrices de su trabajador social y son puntuales, esto debería evitar que cualquiera de las partes entre en contacto con la otra. En caso de cualquier posible retraso, debe comunicarlo a su trabajador social lo antes posible y mantenerse en comunicación con su trabajador social hasta su llegada. Los padres visitantes, cuyas visitas se realizan en la oficina de CFS, deben llegar quince (15) minutos antes de la hora programada para la visita y se irán quince (15) minutos después de la salida del padre/madre custodio/niño.<br /> Periódicamente, su trabajador social puede cambiar este protocolo a su discreción. El protocolo para las visitas realizadas en la comunidad es dirigido por el Tribunal o arreglado por las partes y/o el trabajador social según las circunstancias del caso. CFS siempre retiene la discreción y autoridad para dirigir a las partes respecto a este protocolo."
              formik={formik}
            />

            {/* política 4 */}
            <PolicyCheckbox
              name="policy4"
              label="<b>4. Asistencia:</b> <br/>
      La asistencia regular y consistente es necesaria para cumplir con los requisitos de visitación. Una vez que se programa una sesión, ese espacio de tiempo se reserva específicamente para usted. Se espera que los clientes de CFS asistan a todas las sesiones programadas por su trabajador social, excepto en caso de enfermedad o emergencia. Tres o más cancelaciones durante un período de dos meses (60 días) o dos cancelaciones consecutivas pueden resultar en la terminación de los servicios y la retirada de CFS del caso."
              formik={formik}
            />

            {/* política 5 */}
            <PolicyCheckbox
              name="policy5"
              label="<b>5. Cancelaciones:</b> <br/>
      Todas las cancelaciones requieren un aviso mínimo de veinticuatro (24) horas y deben ser confirmadas por su trabajador social. No adherirse a esta política resultará en una tarifa de cancelación que se le cobrará a la misma tarifa por hora por el tiempo que tenía programado, según el contrato firmado con CFS. Si recibe servicios de CFS sin costo alguno y pagados por el Tribunal y fondos públicos, el Tribunal aún puede ordenar que usted sea responsable de las citas canceladas con CFS. En caso de que esto sea ordenado, se le cobrará a la tarifa por hora de $75/hora."
              formik={formik}
            />

            {/* política 6 */}
            <PolicyCheckbox
              name="policy6"
              label="<b>6.</b> CFS se esfuerza por proporcionar un ambiente neutral, seguro y apropiado para que los niños participen en la visitación. No se permite la discusión sobre el litigio, el desprestigio del otro padre, o la obtención de información personal sobre el otro padre durante su tiempo en CFS. Su trabajador social mantiene la autoridad para intervenir y redirigir cualquier comunicación o comportamiento que se considere inapropiado.<br/><br/> El personal de CFS y la oficina de CFS no deben ser utilizados para el propósito de llevar a cabo litigios. La política de CFS prohíbe la entrega de documentos legales antes, durante o después de la visitación de CFS y en el espacio de la oficina de CFS."
              formik={formik}
            />

            {/* política 7 */}
            <PolicyCheckbox
              name="policy7"
              label="<b>7. Programación:</b> <br/>
            La programación de visitas se organiza teniendo en cuenta la disponibilidad de ambas partes, incluido el niño y el Trabajador Social de CFS. Aunque CFS se esfuerza por ofrecer disponibilidad de personal flexible, su cooperación es esencial para completar los requisitos exigidos por el Tribunal."
              formik={formik}
            />

            {/* política 8 */}
            <PolicyCheckbox
              name="policy8"
              label="<b>8. Regalos:</b> <br/>
            Mientras esté en CFS, a su(s) hijo(s) se le(s) pueden ofrecer regalos u otros artículos para llevar a casa. Por favor, discuta con su trabajador social asignado cualquier objeción o preocupación relacionada con esto antes de que comience la visitación."
              formik={formik}
            />

            {/* política 9 */}
            <PolicyCheckbox
              name="policy9"
              label="<b>9. Comida y Productos de Higiene:</b> <br/>
            CFS no proporciona pañales ni alimentos. Su trabajador social discutirá con usted cualquier requisito/específico suministros necesarios para su hijo con anticipación a su visitación, y quién será responsable de proporcionar estos suministros."
              formik={formik}
            />

            {/* política 10 */}
            <PolicyCheckbox
              name="policy10"
              label="<b>10. Terceros:</b> <br/>
            Generalmente, no se permite la participación de terceros en la visitación sin el consentimiento o autorización del Tribunal. Por favor, discuta con su trabajador social asignado cualquier pregunta/inquietud relacionada con otras personas que puedan estar incluidas en la visitación de su hijo."
              formik={formik}
            />

            {/* nombre y firma del cliente */}
            <FormTextInput
              name="clientName"
              label="Nombre del Cliente:"
              formik={formik}
            />
            <br />

            <Stack direction={"row"} gap={2}>
              <Typography>Firma:</Typography>

              <Box
                sx={{ borderBottom: "1px solid black", width: "fit-content" }}
              >
                <SignatureCanvas
                  ref={clientSigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <Stack
                  direction="row"
                  gap={2}
                  p={2}
                  justifyContent={"flex-end"}
                >
                  <ButtonCommon
                    btnClicked={clearClientSignature}
                    startIcon={false}
                  >
                    Limpiar
                  </ButtonCommon>
                </Stack>
              </Box>
            </Stack>

            <br />

            {/* nombre y firma del trabajador social */}
            <FormTextInput
              name="socialWorker"
              label="Trabajador Social"
              formik={formik}
            />
            <br />

            <Stack direction={"row"} gap={2}>
              <Typography>Firma:</Typography>

              <Box
                sx={{ borderBottom: "1px solid black", width: "fit-content" }}
              >
                <SignatureCanvas
                  ref={socialSigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <Stack
                  direction="row"
                  gap={2}
                  p={2}
                  justifyContent={"flex-end"}
                >
                  <ButtonCommon
                    btnClicked={clearSocialSignature}
                    startIcon={false}
                  >
                    Limpiar
                  </ButtonCommon>
                </Stack>
              </Box>
            </Stack>

            <br />

            <Typography>Fecha: {new Date().toString()}</Typography>
          </div>
          <Stack direction={"row"} p={2} pb={6} gap={4}>
            <ButtonCommon
              startIcon={false}
              btnClicked={() => {
                setAttachTocase(true);
              }}
            >
              Adjuntar al Caso
            </ButtonCommon>
            <ButtonCommon
              btnClicked={() => {
                saveClientSignature();
                saveSocialSignature();
                setTimeout(() => {
                  generatePDF(contentRef, options);
                }, [1000]);
              }}
              startIcon={false}
            >
              Descargar PDF
            </ButtonCommon>
          </Stack>

          {attachTocase && (
            <Stack
              gap={2}
              alignItems={"flex-start"}
              justifyContent={"center"}
              sx={{ width: "fit-content" }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography>¿A qué caso le gustaría adjuntar?</Typography>
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAttachTocase(false);
                  }}
                />
              </Stack>
              <Stack>
                <Typography sx={{ textAlign: "left" }}>
                  Seleccione el caso<span style={{ color: "red" }}>*</span>
                </Typography>
                <Stack sx={{ width: "400px" }}>
                  {/* <AutocompleteInput
                    id="case"
                    name="case"
                    options={caseDropdown}
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    helperText={formik.touched.case && formik.errors.case}
                  /> */}
                  <CaseSearchDropdown
                    name="case"
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    reset={resetDropdown}
                  />
                  {formik.touched.case && formik.errors.case ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.case}
                    </div>
                  ) : null}{" "}
                </Stack>
              </Stack>
              <ButtonCommon startIcon={false} type={"submit"}>
                Adjuntar
              </ButtonCommon>
            </Stack>
          )}
        </form>
      </Box>

      <Box style={{ height: "250px", width: "100%" }}></Box>
      <br />
      <br />
      <h1>pdf data shown below</h1>
      <div
        ref={contentRef}
        style={{ borderBottom: "1px solid gray", paddingBottom: "100px" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="new-page-section">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Servicios Integrales para la Familia
                </Typography>
                <Typography variant="subtitle1">
                  Servicios Forenses y Terapéuticos
                </Typography>
                <Typography variant="subtitle2">
                  450 Lexington Ave, 4th Floor, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            <br />

            <Stack direction={"row"} justifyContent={"center"}>
              <Typography>
                POLÍTICAS DE VISITAS DE SERVICIOS INTEGRALES PARA LA FAMILIA
                PARA PADRES CUSTODIOS
              </Typography>
            </Stack>
            <br />
            <br />
            <Typography>
              Servicios Integrales para la Familia (CFS) es una agencia privada
              que proporciona servicios de Trabajadores Sociales Licenciados y
              otros profesionales. Los servicios de CFS son designados/dirigidos
              por los Tribunales y también pueden ser contratados de forma
              privada.
            </Typography>
            <br />
            <Typography>Fecha: {new Date().toString()}</Typography>
            {/* el caso está asignado a  */}
            <FormTextInput
              name="assignedTo"
              label="Su caso está asignado a"
              formik={formik}
            />
            <br />
            {/* método de comunicación  */}
            <FormControl>
              <Typography id="demo-controlled-radio-buttons-group">
                El método de comunicación más eficiente de su trabajador social
                es:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="methodofCommun"
                value={formik.values.methodofCommun}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="Phone/Text"
                  control={<Radio color="black" />}
                  label="Teléfono/Texto"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio color="black" />}
                  label="Correo Electrónico"
                />
              </RadioGroup>
              {formik.touched.methodofCommun &&
                formik.errors.methodofCommun && (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.methodofCommun}
                  </div>
                )}
            </FormControl>
            {/* información de contacto del trabajador social  */}
            <FormTextInput
              name="contactInfo"
              label="La información de contacto de su trabajador social es"
              formik={formik}
            />
            <br />
            {/* información de contacto y método preferido de comunicación  */}
            <Stack>
              <Typography>
                Indique su información de contacto y el método preferido de
                comunicación marcando tantos como sea necesario:
              </Typography>
              <FormCheckboxInput
                name="email"
                label="Correo Electrónico"
                formik={formik}
              />
              <FormCheckboxInput
                name="phoneCalls"
                label="Llamadas Telefónicas"
                formik={formik}
              />
              <FormCheckboxInput name="text" label="Texto" formik={formik} />
            </Stack>
            <br />
            <FormTextInput
              name="clientName"
              label="Nombre del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientAddress"
              label="Dirección del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientPhone"
              label="Teléfono del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientEmail"
              label="Correo Electrónico del Cliente"
              formik={formik}
            />

            <br />

            <Typography variant="h5">POLÍTICAS</Typography>

            <br />

            {/* política 1  */}
            <PolicyCheckbox
              name="policy1"
              label="<b> 1. Sustitución: </b> </br> En el caso de que su trabajador social no esté disponible, su trabajador social puede utilizar la asistencia de otro asociado de CFS. Cuando y si esto ocurre, se le proporcionará la información de contacto del trabajador social delegado."
              formik={formik}
            />

            {/* política 2  */}
            <PolicyCheckbox
              name="policy2"
              label="<b> 2. Confidencialidad: </b> </br> El papel de CFS es el de una parte neutral. Esto implica potencialmente la liberación de toda la información relevante recopilada y observada a lo largo de nuestra participación con su familia al Tribunal."
              formik={formik}
            />

            {/* política 3  */}
            <PolicyCheckbox
              name="policy3"
              label="<b>3. Puntualidad:</b> <br /> Se debe hacer todo lo posible para llegar a la hora programada/instruida. Si las partes siguen las directrices de su trabajador social y son puntuales, esto debería evitar que las partes entren en contacto entre sí. En caso de cualquier posible tardanza, debe comunicar esto a su trabajador social tan pronto como sea posible y mantenerse en comunicación con su trabajador social hasta su llegada. Los padres que visitan, cuyas visitas ocurren en la oficina de CFS, deben llegar quince (15) minutos antes de la hora programada de la visita y partir quince (15) minutos después de la salida del padre/madre custodio/niño.
          <br />
          Periódicamente, su trabajador social puede cambiar este protocolo a su discreción. El protocolo para visitas realizadas en la comunidad es dirigido por el Tribunal o arreglado por las partes y/o el trabajador social según las circunstancias del caso. CFS siempre retiene la discreción y autoridad para dirigir a las partes respecto a este protocolo."
              formik={formik}
            />

            {/* política 4  */}
            <PolicyCheckbox
              name="policy4"
              label="<b>4. Asistencia</b> <br/>
          La asistencia regular y consistente es necesaria para cumplir con los requisitos de visita. Una vez programada una sesión, ese espacio de tiempo está reservado específicamente para usted. Se espera que los clientes de CFS asistan a todas las sesiones programadas por su Trabajador Social, excepto en caso de enfermedad o emergencia. Tres o más cancelaciones durante un período de dos meses (60 días) o dos cancelaciones consecutivas pueden resultar en la terminación de los servicios y el retiro de CFS del caso."
              formik={formik}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="new-page-section">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Servicios Integrales para la Familia
                </Typography>
                <Typography variant="subtitle1">
                  Servicios Forenses y Terapéuticos
                </Typography>
                <Typography variant="subtitle2">
                  450 Lexington Ave, 4th Floor, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            {/* política 5  */}
            <PolicyCheckbox
              name="policy5"
              label="<b>5. Cancelaciones:</b> <br/>
          Todas las cancelaciones requieren un mínimo de veinticuatro (24) horas de aviso y deben ser confirmadas por su trabajador social. El incumplimiento de esta política resultará en una tarifa de cancelación que se le cobrará a la misma tarifa por hora por el tiempo que estaba programado, según el contrato firmado con CFS.
          Si está recibiendo servicios de CFS sin cargo y pagados por el Tribunal y fondos públicos, el Tribunal aún puede ordenar que sea responsable de las citas canceladas con CFS. En caso de que esto sea ordenado, se le cobrará a la tarifa por hora de $75/hora."
              formik={formik}
            />

            {/* política 6  */}
            <PolicyCheckbox
              name="policy6"
              label="<b>6.</b> CFS se esfuerza por proporcionar un ambiente neutral, seguro y apropiado para que los niños participen en la visita. No se permite la discusión sobre el litigio, el desprecio del otro padre, la obtención de información personal sobre el otro padre durante su tiempo en CFS. Su trabajador social tiene la autoridad para intervenir y redirigir cualquier comunicación o comportamiento que se considere inapropiado.<br/> <br/> El personal de CFS y la oficina de CFS no deben ser utilizados con el propósito de proseguir con el litigio. La política de CFS prohíbe la entrega de documentos legales antes, durante o después de la visita de CFS y en el espacio de la oficina de CFS."
              formik={formik}
            />

            {/* política 7  */}
            <PolicyCheckbox
              name="policy7"
              label="<b>7. Programación:</b> <br/>
            La programación de visitas se organiza teniendo en cuenta la disponibilidad de ambas partes, incluido el niño y el Trabajador Social de CFS. Aunque CFS se esfuerza por ofrecer disponibilidad de personal flexible, su cooperación es esencial para completar los requisitos exigidos por el Tribunal."
              formik={formik}
            />

            {/* política 8  */}
            <PolicyCheckbox
              name="policy8"
              label="<b>8. Regalos:</b> <br/>
            Mientras esté en CFS, a su(s) hijo(s) se le(s) pueden ofrecer regalos u otros artículos para llevar a casa. Por favor, discuta con su trabajador social asignado cualquier objeción o preocupación relacionada con esto antes de que comience la visita."
              formik={formik}
            />

            {/* política 9  */}
            <PolicyCheckbox
              name="policy9"
              label="<b>9.Comida y Artículos de Aseo:</b> <br/>
            CFS no proporciona pañales ni comida. Su trabajador social discutirá con usted cualquier requisito/especificaciones necesarios para su hijo antes de su visita, y quién será responsable de proporcionar estos suministros."
              formik={formik}
            />

            {/* política 10  */}
            <PolicyCheckbox
              name="policy10"
              label="<b>10. Terceros:</b> <br/>
            En general, no se permite la participación de terceros en la visita sin el consentimiento o la autorización del Tribunal. Por favor, discuta con su trabajador social asignado cualquier pregunta o inquietud relacionada con otras personas que puedan estar incluidas en la visita de su hijo."
              formik={formik}
            />

            <Stack direction={"row"}>
              {/* nombre y firma del cliente  */}
              <Box sx={{ width: "50%" }}>
                <FormTextInput
                  name="clientName"
                  label="Nombre del Cliente"
                  formik={formik}
                />
                <br />
                <Stack direction={"row"} gap={2}>
                  <Typography>Firma:</Typography>

                  <img src={clientSign} alt=""></img>
                </Stack>
              </Box>
              {/* nombre y firma del trabajador social  */}
              <Box sx={{ width: "50%" }}>
                <FormTextInput
                  name="socialWorker"
                  label="Trabajador Social"
                  formik={formik}
                />
                <br />
                <Stack direction={"row"} gap={2}>
                  <Typography>Firma:</Typography>

                  <img src={workerSign} alt=""></img>
                </Stack>
              </Box>
            </Stack>
            <br />

            <Typography>Fecha: {new Date().toString()}</Typography>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default CustodialForm;

import { Box } from "@mui/material";
import React from "react";
import MainHeader from "../../shared/MainHeader";
// import CasesTable from "../../shared/CasesTable";
// import moment from "moment";
import MyOpenCaseTable from "../../components/MyOpenCaseTable";

// const columnsData = [
//   {
//     name: <div>Received Date</div>,
//     selector: (row) =>
//       row.ReceivedDate ? moment(row.ReceivedDate).format("LLL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Adjourned Date</div>,
//     selector: (row) =>
//       row.AdjournedDate ? moment(row.AdjournedDate).format("LLL") : "null",
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   // {
//   //   name: <div>Adjourned Time</div>,
//   //   selector: (row) =>
//   //     row.AdjournedTime
//   //       ? moment(row.AdjournedTime, "HH:mm:ss").format("LTS")
//   //       : "null",

//   //   sortable: true,
//   //   wrap: true,
//   //   grow: 2,
//   // },
//   {
//     name: <div>Court</div>,
//     selector: (row) => row.Court,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
//   {
//     name: <div>Judge</div>,
//     selector: (row) => row.Judge,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },

//   // {
//   //   name: <div>Appearance</div>,
//   //   selector: (row) => row.Appearance,
//   //   sortable: true,
//   //   wrap: true,
//   //   grow: 3,
//   // },
//   {
//     name: <div>Report</div>,
//     selector: (row) => row.Report,
//     sortable: true,
//     wrap: true,
//     // grow: 2,
//   },
// ];

const OpenCases = () => {
  return (
    <Box>
      <MainHeader headingText={"My Open Cases"} />

      {/* <CasesTable
        columnsData={columnsData}
        status={"open"}
        caseType={""}
        currentUserId={true}
      /> */}

      <MyOpenCaseTable />
    </Box>
  );
};

export default OpenCases;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MainHeader from "../../shared/MainHeader";
import TextInput from "../../shared/TextInput";
import ButtonCommon from "../../shared/ButtonCommon";
import TextAreaInput from "../../shared/TextAreaInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PUT } from "../../api/ApiService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EncryptionHelper from "../../shared/EncryptionHelper";

const UpdateNote = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const editNotesData = useSelector((state) => state.editnoteReducer.editNotes);

  const formik = useFormik({
    initialValues: {
      subject: editNotesData?.Subject,
      case: editNotesData?.CaseName,
      note: editNotesData?.Note,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        Subject: values.subject,
        Note: values.note,
        ModifiedByUserID: userDetails?.id,
      };

      console.log("data to be encrypted =>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT(`case-notes/update/${editNotesData?.NoteID}`, { encryptData })
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  return (
    <Box>
      <MainHeader headingText="Update Note" />
      <Stack
        p={4}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {/* row one */}
            <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Subject<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <TextInput
                    name="subject"
                    placeholder=""
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.subject}
                    startIcon={false}
                  />

                  {formik.touched.subject && formik.errors.subject ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.subject}
                    </div>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
            {/* row two */}
            <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Case<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <TextInput
                    name="case"
                    placeholder=""
                    disabled={true}
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.case}
                    startIcon={false}
                  />

                  {formik.touched.case && formik.errors.case ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.case}
                    </div>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
            {/* row two */}
            <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Note<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <TextAreaInput
                    name="note"
                    placeholder=""
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.note}
                    rows={10}
                    helperText=""
                  />
                  {formik.touched.note && formik.errors.note ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.note}
                    </div>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
            {/* row four */}
            {/* <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              ></Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FileInput onFileChange={handleFileChange} />
              </Grid>
            </Grid> */}

            {/* row three */}
            <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              ></Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Stack direction={"row"} gap={4}>
                  <ButtonCommon
                    btnClicked={() => {
                      window.history.back();
                    }}
                  >
                    Cancel
                  </ButtonCommon>
                  <ButtonCommon type="submit">Save</ButtonCommon>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};

export default UpdateNote;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import ButtonCommon from "../../shared/ButtonCommon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import NumberInput from "../../shared/NumberInput";
import TextAreaInput from "../../shared/TextAreaInput";
import { POST } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CaseSearchDropdown from "../../shared/CaseSearchDropdown";
import SelectInput from "../../shared/SelectInput";

const statusData = [
  {
    value: "New",
    label: "New",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Fulfilled",
    label: "Fulfilled",
  },
  {
    value: "Denied",
    label: "Denied",
  },
];

const RequestSuppOrder = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [resetDropdown, setResetDropdown] = useState(false);

  // Reset the dropdown reset state after it has been set to true
  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const formik = useFormik({
    initialValues: {
      case: null,
      estimatedHours: "",
      comment: "",
      statusRemarks: "",
      status: "New",
    },
    validationSchema: Yup.object({
      case: Yup.object().required("Case is required"),
      estimatedHours: Yup.number().required("Hours is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const postData = {
        CaseID: values.case.CaseID,
        EstimatedHours: values.estimatedHours,
        Comment: values.comment,
        CreatedByUserID: userDetails?.id,
        StatusRemarks: values.statusRemarks,
        Status: values.status,
      };

      console.log("data to be encrypted =>", postData);

      const encryptData = EncryptionHelper.encryptData(postData);

      POST("supplement-oreder/create", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          resetForm();
          setResetDropdown(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
          console.log(err);
        });
    },
  });

  return (
    <Box>
      <MainHeader headingText={"Request Supplemental Order"} />
      <Stack
        gap={3}
        p={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.92px",
                textAlign: "left",
              }}
            >
              Create a new Supplemental Order Request{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "18.27px",
                textAlign: "left",
              }}
            >
              You will be notified when the Supplemental Order has been
              fulfilled.
            </Typography>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              {/* 1  */}
              <Grid item xs={4}>
                <Stack>
                  {" "}
                  <Typography sx={{ textAlign: "left" }}>
                    Case<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <CaseSearchDropdown
                      name="case"
                      value={formik.values.case}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("case", newValue);
                      }}
                      onBlur={formik.handleBlur}
                      reset={resetDropdown}
                    />
                    {formik.touched.case && formik.errors.case ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.case}
                      </div>
                    ) : null}{" "}
                  </Stack>
                </Stack>
              </Grid>

              {/* 2 */}
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>Status</Typography>
                  <Stack sx={{ width: "100%" }}>
                    <SelectInput
                      disabled={true}
                      name="status"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.status}
                      dropDownData={statusData}
                      helperText=""
                    />

                    {formik.touched.status && formik.errors.status ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.status}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 3  */}
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Estimated Hours<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <NumberInput
                      name="estimatedHours"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.estimatedHours}
                      startIcon={false}
                      helperText={
                        "Maximum number of hours to bill against (as stated on the court order)"
                      }
                    />
                    {formik.touched.estimatedHours &&
                    formik.errors.estimatedHours ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.estimatedHours}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 4 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>Comment</Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="comment"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.comment}
                      helperText=""
                    />
                    {formik.touched.comment && formik.errors.comment ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.comment}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 5 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Status Remarks
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="statusRemarks"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.statusRemarks}
                      helperText="For admin use"
                    />
                    {formik.touched.statusRemarks &&
                    formik.errors.statusRemarks ? (
                      <div
                        style={{
                          fontSize: "smaller",

                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.statusRemarks}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 6 */}
              <Grid item xs={12}>
                <ButtonCommon type="submit">Save</ButtonCommon>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Stack>
    </Box>
  );
};

export default RequestSuppOrder;

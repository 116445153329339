import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY;

const EncryptionHelper = {
  // Encrypt data
  encryptData: (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  },

  // Decrypt data
  decryptData: (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  },
};

export default EncryptionHelper;

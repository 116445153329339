import React, { useState } from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

// assets
import logo from "../../assets/logo.png";
import unsplash from "../../assets/loginpage/pexels-pixabay.jpg";

// mui icons
import BackToLogin from "../../components/BackToLogin/index";
import ResetPassword from "../../components/ResetPassword/index";
import SendOtp from "../../components/SendOtp/index";
import VerifyOtp from "../../components/VerifyOtp/index";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [activeStack, setActiveStack] = useState(1);

  const sendOtpHandler = () => {
    setActiveStack(2);
  };

  const verifyOtpHandler = () => {
    setActiveStack(3);
  };

  const resetPasswordHandler = () => {
    setActiveStack(4);
  };

  const backtoLoginHandler = () => {
    navigate("/login");
  };

  return (
    <Box>
      <Grid container>
        {!belowTablet && (
          <Grid item xs={12} sm={6}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100vh",
                backgroundImage: `url(${unsplash})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <Box sx={{ width: "70%", margin: "auto" }}>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "32px",
                    fontWeight: "500",
                    lineHeight: "44px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                  }}
                >
                  The only way to do great work is to love what you do.
                </Typography>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "36px",
                    letterSpacing: "-0.02em",
                    textAlign: "right",
                  }}
                >
                  - Steve Jobs{" "}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingTop: "100px",
            }}
          >
            <Stack
              sx={{ height: "300px", width: "60%" }}
              justifyContent="center"
              alignItems="center"
              gap={5}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "246px",
                }}
              ></img>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: "600",
                  lineHeight: "45px",
                  letterSpacing: "0.10000000149011612px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                {activeStack === 4 ? "Recovery Password" : "Forgot Password"}
              </Typography>

              {activeStack === 1 && <SendOtp sendOtpHandler={sendOtpHandler} />}

              {activeStack === 2 && (
                <VerifyOtp verifyOtpHandler={verifyOtpHandler} />
              )}

              {activeStack === 3 && (
                <ResetPassword resetPasswordHandler={resetPasswordHandler} />
              )}

              {activeStack === 4 && (
                <BackToLogin backtoLoginHandler={backtoLoginHandler} />
              )}
            </Stack>
          </Box>{" "}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;

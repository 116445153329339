import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const DateInput = ({
  name,
  placeholder,
  onChangeValue,
  onBlurValue,
  value,
  startIcon = false,
  helperText,
  type = "date",
}) => {
  return (
    <TextField
      type={type}
      name={name}
      placeholder={placeholder}
      fullWidth
      helperText={helperText}
      color="black"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {startIcon && <PersonOutlineIcon />}
          </InputAdornment>
        ),
      }}
      onChange={onChangeValue}
      onBlur={onBlurValue}
      value={value}
      sx={{
        "& .MuiInputBase-root": {
          height: "35px",
        },
      }}
    />
  );
};

export default DateInput;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonCommon from "../../shared/ButtonCommon";
import SelectInput from "../../shared/SelectInput";
import DateInput from "../../shared/DateInput";
import { useSelector } from "react-redux";
import { GET, POST } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusData } from "../../utils/selectActivity";
import CaseSearchDropdown from "../../shared/CaseSearchDropdown";

const CreateNewDar = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  const [billedHours, setBilledHours] = useState();
  // testing
  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const getConsumedTime = (newValue) => {
    if (newValue) {
      GET(`daily-activity/total-consumed-time?caseID=${newValue?.CaseID}`)
        .then((response) => {
          const encryptedData = response?.encryptedData;
          const decryptedData = EncryptionHelper.decryptData(encryptedData);
          console.log("setBilledHours decryptedData ====>", decryptedData);
          setBilledHours(decryptedData);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  console.log("caseDetails ====>", caseDetails);

  const formik = useFormik({
    initialValues: {
      case: null,
      activity: "",
      activityStart: "",
      activityEnd: "",
    },
    validationSchema: Yup.object({
      case: Yup.object().required("Case is required"),
      activity: Yup.string().required("Activity is required"),
      activityStart: Yup.date()
        .required("Start date is required")
        .test(
          "is-within-24-hours",
          "Start date cannot be more than 24 hours before the end date",
          function (value) {
            const { activityEnd } = this.parent;
            return activityEnd
              ? value >=
                  new Date(activityEnd).setHours(
                    new Date(activityEnd).getHours() - 24
                  )
              : true;
          }
        ),
      activityEnd: Yup.date()
        .required("End date is required")
        .min(Yup.ref("activityStart"), "End date must be after start date"),
    }),

    onSubmit: (values, { resetForm }) => {
      if (caseDetails?.Status === "Closed") {
        toast.error(
          "Unable to create Daily Activity Report against a closed case."
        );
        return;
      }

      const timeDifference = calculateTimeDifference(
        values.activityStart,
        values.activityEnd
      );

      const remainingHours =
        caseDetails?.HoursLimit - billedHours?.billed_hours;

      if (timeDifference > remainingHours && caseDetails?.HoursLimit != null) {
        toast.warning(
          `The activity duration ${timeDifference.toFixed(
            2
          )} hours exceeds the allowed hours limit.`
        );
        return;
      }
      if (remainingHours < 10) {
        toast.warning("You have hours remaining less than 10.");
        // return;
      }

      const postData = {
        CaseID: values?.case?.CaseID,
        FinishTime: values.activityEnd,
        Exported: false,
        ServiceOrActivity: values.activity,
        StartTime: values.activityStart,
        CreatedByUserID: userDetails?.id,
      };

      console.log("data to be encrypted ==>", postData);

      const encryptData = EncryptionHelper.encryptData(postData);

      POST("daily-activity/create", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          resetForm();
          setResetDropdown(true);
          setCaseDetails(null);
          setBilledHours(null);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  function calculateTimeDifference(startTime, finishTime) {
    const start = new Date(startTime);
    const finish = new Date(finishTime);
    const differenceInMilliseconds = finish - start;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return differenceInHours;
  }

  return (
    <Box>
      <MainHeader headingText="Create New DAR" />
      <Stack
        p={4}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.92px",
                textAlign: "left",
              }}
            >
              Create a new Daily Activity Report
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "18.27px",
                textAlign: "left",
              }}
            >
              ⓘ Select the case name, activity, set the start and end
              dates/times, and press submit.
            </Typography>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              {/* 1  */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Case<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <CaseSearchDropdown
                      name="case"
                      value={formik.values.case}
                      onChange={(event, newValue) => {
                        setCaseDetails(newValue);
                        formik.setFieldValue("case", newValue);
                        getConsumedTime(newValue);
                      }}
                      onBlur={formik.handleBlur}
                      reset={resetDropdown}
                      filtered={true}
                    />

                    {formik.touched.case && formik.errors.case ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.case}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 2  */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Select Activity<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <SelectInput
                      name="activity"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activity}
                      dropDownData={statusData}
                      helperText=""
                    />
                    {formik.touched.activity && formik.errors.activity ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activity}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 3 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Activity Start Date & Time
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    {/* <DateInput
                      type="datetime-local"
                      name="activityStart"
                      placeholder="activityStart"
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityStart}
                      helperText={""}
                    /> */}

                    <DateInput
                      type="datetime-local"
                      name="activityStart"
                      placeholder="activityStart"
                      onChangeValue={(e) => {
                        const selectedDate = e.target.value;
                        formik.setFieldValue("activityStart", selectedDate);
                        formik.setFieldValue("activityEnd", selectedDate); // Set activityEnd to the same value
                      }}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityStart}
                      helperText={""}
                    />

                    {formik.touched.activityStart &&
                    formik.errors.activityStart ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityStart}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 4 */}
              <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Activity end Date & Time
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityEnd"
                      placeholder="activityEnd"
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityEnd}
                      helperText={""}
                    />
                    {formik.touched.activityEnd && formik.errors.activityEnd ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityEnd}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* 5 */}
              {caseDetails && (
                <Grid item xs={12}>
                  <Stack>
                    <Typography sx={{ textAlign: "left" }}>
                      Billing hours limit for this Case:{" "}
                      {caseDetails?.HoursLimit ?? 0}
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      Total hours billed: {billedHours?.billed_hours ?? 0}
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      Hours remaining:{" "}
                      {caseDetails?.HoursLimit - billedHours?.billed_hours}
                    </Typography>
                  </Stack>
                </Grid>
              )}

              {/* 6 */}
              <Grid item xs={12}>
                <ButtonCommon type="submit">Save</ButtonCommon>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CreateNewDar;

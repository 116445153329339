import React, { useState, memo } from "react";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { storeThemeColor } from "../../slices/themeSlice";

const DarkLightMode = () => {
  const dispatch = useDispatch();
  const themeColor = useSelector((state) => state.themeColorReducer.lightColor);

  const [lightmode, setLightMode] = useState(themeColor || false);

  const lightModeHandler = () => {
    setLightMode(!lightmode);
    dispatch(storeThemeColor(!lightmode));
  };

  return (
    <Stack sx={{ cursor: "pointer" }} onClick={lightModeHandler}>
      {lightmode ? (
        <LightModeIcon fontSize="large" />
      ) : (
        <DarkModeIcon fontSize="large" />
      )}
    </Stack>
  );
};

export default memo(DarkLightMode);

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Stack } from "@mui/material";
import PasswordInput from "../../shared/PasswordInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST } from "../../api/ApiService";
import { useNavigate } from "react-router-dom";
import EncryptionHelper from "../../shared/EncryptionHelper";

const ResetPassword = ({ resetPasswordHandler }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      const passResetEmail = localStorage.getItem("passResetEmail");

      const encryptData = EncryptionHelper.encryptData({
        email: passResetEmail,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      });

      POST(`reset-password`, { encryptData })
        .then((resp) => {
          localStorage.removeItem("passResetEmail");
          toast.success(resp?.message);
          resetPasswordHandler();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={4}
        width="80%"
        m="auto"
      >
        <Box sx={{ width: "100%" }}>
          <PasswordInput
            name="newPassword"
            placeholder="New Password"
            onChangeValue={formik.handleChange}
            onBlurValue={formik.handleBlur}
            value={formik.values.newPassword}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div
              style={{
                fontSize: "smaller",
                color: "red",
                textAlign: "left",
              }}
            >
              {formik.errors.newPassword}
            </div>
          ) : null}
        </Box>

        <Box sx={{ width: "100%" }}>
          <PasswordInput
            name="confirmPassword"
            placeholder="Confirm Password"
            onChangeValue={formik.handleChange}
            onBlurValue={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div
              style={{
                fontSize: "smaller",
                color: "red",
                textAlign: "left",
              }}
            >
              {formik.errors.confirmPassword}
            </div>
          ) : null}
        </Box>

        <Button type="submit" variant="contained" fullWidth>
          Reset Password
        </Button>

        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            navigate("/login");
          }}
        >
          Go back to login
        </Button>
      </Stack>
    </form>
  );
};

export default ResetPassword;

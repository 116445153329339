import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allcase: [],
};

export const allcaseSlice = createSlice({
  name: "allcase",
  initialState,
  reducers: {
    storeAllCase: (state, action) => {
      state.allcase = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeAllCase } = allcaseSlice.actions;

export default allcaseSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// assets
import mainCases from "../assets/main-menu-icons/MainCases.png";
import cases from "../assets/main-menu-icons/cases.png";
import openCases from "../assets/main-menu-icons/openCases.png";
import closedCases from "../assets/main-menu-icons/closedCases.png";
import privateCases from "../assets/main-menu-icons/assignPvtCase.png";
import publicCases from "../assets/main-menu-icons/assignPublicCase.png";
import caseOnHold from "../assets/main-menu-icons/holdCases.png";
import openNewCase from "../assets/main-menu-icons/openNewCase.png";
import caseAdjournment from "../assets/main-menu-icons/caseAdjournment.png";
import commonActions from "../assets/main-menu-icons/commonAction.png";
import dar from "../assets/main-menu-icons/DAR.png";
import document from "../assets/main-menu-icons/documents.png";
import supplemental from "../assets/main-menu-icons/supplementalOrder.png";
import help from "../assets/main-menu-icons/help.png";
import createCourtReport from "../assets/main-menu-icons/createCourtReport.png";
import common from "../assets/main-menu-icons/myDar.png";
import sharedDoc from "../assets/main-menu-icons/sharedDoc.png";
import supplementalOrder from "../assets/main-menu-icons/supplementalOrder.png";
import requestSupplement from "../assets/main-menu-icons/requestSupplement.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MainMenu = ({ isOpen, handleDrawerToggle }) => {
  let data = [
    {
      label: "Cases",
      icon: mainCases,
      panel: "panel1",
      child: [
        {
          childLabel: "Cases",
          childIcon: cases,
          route: "/cases",
          parent: "Cases",
          permissionId: 1,
        },
        {
          childLabel: "My Open Cases",
          childIcon: openCases,
          route: "/opencases",
          parent: "Cases",
          permissionId: 2,
        },
        {
          childLabel: "Closed Cases",
          childIcon: closedCases,
          route: "/closedcases",
          parent: "Cases",
          permissionId: 3,
        },
        {
          childLabel: "Assign Private Case",
          childIcon: privateCases,
          route: "/pvtcase",
          parent: "Cases",
          permissionId: 5,
        },
        {
          childLabel: "Assign Public Case",
          childIcon: publicCases,
          route: "/publiccase",
          parent: "Cases",
          permissionId: 6,
        },
        {
          childLabel: "Private Cases on hold",
          childIcon: caseOnHold,
          route: "/caseonhold",
          parent: "Cases",
          permissionId: 7,
        },
        {
          childLabel: "Public Cases on hold",
          childIcon: caseOnHold,
          route: "/pubcaseonhold",
          parent: "Cases",
          permissionId: 8,
        },
        {
          childLabel: "Pub/Pvt Cases on hold",
          childIcon: caseOnHold,
          route: "/pubpvtcaseonhold",
          parent: "Cases",
          permissionId: 8,
        },
        {
          childLabel: "Open a New Case",
          childIcon: openNewCase,
          route: "/addcase",
          parent: "Cases",
          permissionId: 9,
        },
        {
          childLabel: "Case Adjourments",
          childIcon: caseAdjournment,
          route: "/caseadjournment",
          parent: "Cases",
          permissionId: 10,
        },
      ],
    },
    {
      label: "Common Action",
      icon: commonActions,
      panel: "panel2",
      child: [
        {
          childLabel: "Create Court Report",
          childIcon: createCourtReport,
          route: "/courtreport",
          parent: "Common Action",
          permissionId: 11,
        },
        {
          childLabel: "Create Note",
          childIcon: common,
          route: "/createnote",
          parent: "Common Action",
          permissionId: 12,
        },
      ],
    },
    {
      label: "Daily Activity Reports",
      icon: dar,
      panel: "panel3",
      child: [
        {
          childLabel: "Create New DAR",
          childIcon: createCourtReport,
          route: "/newdar",
          parent: "Daily Activity Reports",
          permissionId: 13,
        },
        {
          childLabel: "My DARs",
          childIcon: common,
          route: "/mydar",
          parent: "Daily Activity Reports",
          permissionId: 14,
        },
        {
          childLabel: "Export Activities",
          childIcon: common,
          route: "/exportactivity",
          parent: "Daily Activity Reports",
          permissionId: 15,
        },
      ],
    },
    {
      label: "Documents",
      icon: document,
      panel: "panel4",
      child: [
        {
          childLabel: "Shared Document",
          childIcon: sharedDoc,
          route: "/shareddoc",
          parent: "Documents",
          permissionId: 16,
        },
        {
          childLabel: "Client Forms",
          childIcon: common,
          route: "/clientforms",
          parent: "Documents",
          permissionId: 17,
        },
      ],
    },
    {
      label: "Supplemental Order",
      icon: supplemental,
      panel: "panel5",

      child: [
        {
          childLabel: "Request Supplemental Order",
          childIcon: supplementalOrder,
          route: "/supplementalOrder",
          parent: "Supplemental Order",
          permissionId: 18,
        },
        {
          childLabel: "Order Request",
          childIcon: requestSupplement,
          route: "/orderrequest",
          parent: "Supplemental Order",
          permissionId: 19,
        },
      ],
    },
    {
      label: "Manage Roles",
      icon: help,
      panel: "panel7",
    },
    {
      label: "Permissions",
      icon: help,
      panel: "panel8",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [expanded, setExpanded] = useState(false);
  const [selectedLink, setSelectedLink] = useState("");
  const [parentLabel, setParentLabel] = useState("");

  if (userDetails?.role !== 1) {
    data = data.filter(
      (item) => item.panel !== "panel8" && item.panel !== "panel7"
    );
  }

  const permissionsIdArray = userDetails?.permission?.map((item) => {
    return item.permission_id;
  });

  if (permissionsIdArray.includes(27)) {
    data.push({
      label: "Audit",
      icon: help,
      panel: "panel9",
    });
  } else if (
    !permissionsIdArray.includes(27) &&
    data.some((item) => item.label === "Audit")
  ) {
    data = data.filter((item) => item.label !== "Audit");
  }

  const filterDataByPermissions = (data, permissionsIdArray) => {
    return data
      .map((section) => {
        if (section.child) {
          const filteredChildren = section.child.filter((child) =>
            permissionsIdArray?.includes(child.permissionId)
          );
          return { ...section, child: filteredChildren };
        }
        return section;
      })
      .filter((section) => {
        // Only keep sections where panel is between panel1 and panel5 (inclusive) and child is not empty
        const panelNumber = parseInt(section.panel.replace("panel", ""), 10);
        if (panelNumber >= 1 && panelNumber <= 5) {
          return section.child.length > 0;
        }
        return true; // Keep sections with no child array or panels outside the range 1-5
      });
  };

  const filteredData = filterDataByPermissions(data, permissionsIdArray);

  useEffect(() => {
    if (isOpen) {
      switch (location.pathname) {
        case "/dashboard":
          setParentLabel("dashboard");
          break;
        case "/cases":
          setSelectedLink("Cases");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/opencases":
          setSelectedLink("My Open Cases");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/closedcases":
          setSelectedLink("Closed Cases");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/pvtcase":
          setSelectedLink("Assign Private Case");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/publiccase":
          setSelectedLink("Assign Public Case");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/caseonhold":
          setSelectedLink("Private Cases on hold");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/pubcaseonhold":
          setSelectedLink("Public Cases on hold");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/caseadjournment":
          setSelectedLink("Case Adjourments");
          setParentLabel("Cases");
          setExpanded("panel1");
          break;
        case "/courtreport":
          setSelectedLink("Create Court Report");
          setParentLabel("Common Action");
          setExpanded("panel2");
          break;
        case "/createnote":
          setSelectedLink("Create Note");
          setParentLabel("Common Action");
          setExpanded("panel2");
          break;
        case "/newdar":
          setSelectedLink("Create New DAR");
          setParentLabel("Daily Activity Reports");
          setExpanded("panel3");
          break;
        case "/mydar":
          setSelectedLink("My DARs");
          setParentLabel("Daily Activity Reports");
          setExpanded("panel3");
          break;
        case "/exportactivity":
          setSelectedLink("Export Activities");
          setParentLabel("Daily Activity Reports");
          setExpanded("panel3");
          break;
        case "/shareddoc":
          setSelectedLink("Shared Document");
          setParentLabel("Documents");
          setExpanded("panel4");
          break;
        case "/clientforms":
          setSelectedLink("Client Forms");
          setParentLabel("Documents");
          setExpanded("panel4");
          break;
        case "/supplementalOrder":
          setSelectedLink("Request Supplemental Order");
          setParentLabel("Supplemental Order");
          setExpanded("panel5");
          break;
        case "/orderrequest":
          setSelectedLink("Order Request");
          setParentLabel("Supplemental Order");
          setExpanded("panel5");
          break;

        case "/manageroles":
          setSelectedLink("");
          setParentLabel("Manage Roles");
          break;
        case "/permissions":
          setSelectedLink("");
          setParentLabel("Permissions");
          break;
        case "/audit":
          setSelectedLink("");
          setParentLabel("Audit");
          break;

        default:
          // Handle default case if needed
          break;
      }
    } else {
      setExpanded(false);
    }
  }, [isOpen, location]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded && isOpen ? panel : false);
  };

  const listBtnHandler = (parent, child, route) => {
    setParentLabel(parent);
    setSelectedLink(child);
    navigate(route);
  };

  return (
    <div style={{ height: "100%" }}>
      {isOpen && (
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              background: parentLabel === "dashboard" ? "#01997D" : "black",
              "&:hover": {
                background: "#3DCBB1",
                borderRadius: "6px",
              },
            }}
            onClick={() => {
              setParentLabel("dashboard");
              setSelectedLink("");
              setExpanded(false);
              navigate("/dashboard");
            }}
          >
            <ListItemText
              primary="Dashboard"
              sx={{
                color: "#FFFFFF",
                fontWeight: "400",
                lineHeight: "34px",
                letterSpacing: "0em",
                textAlign: "left",
                "& .MuiTypography-root": {
                  fontSize: "20px",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      )}

      {filteredData?.map((item, index) => (
        <Accordion
          expanded={expanded === item.panel}
          onChange={handleChange(item.panel)}
          key={index}
          sx={{ background: "black" }}
        >
          <AccordionSummary
            expandIcon={
              item.child ? <ExpandMoreIcon sx={{ color: "white" }} /> : ""
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              background: parentLabel === item.label ? "#01997D" : "black",
              color: "white",
              "&:hover": {
                background: "#3DCBB1",
              },
            }}
            onClick={() => {
              if (!item.child && isOpen) {
                if (item.label === "Manage Roles") {
                  setParentLabel("Manage Roles");
                  setSelectedLink("");
                  navigate("/manageroles");
                }
                if (item.label === "Permissions") {
                  setParentLabel("Permissions");
                  setSelectedLink("");
                  navigate("/permissions");
                }
                if (item.label === "Audit") {
                  setParentLabel("Audit");
                  setSelectedLink("");
                  navigate("/audit");
                }
              }

              if (!isOpen) {
                handleDrawerToggle();
              }
            }}
          >
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    "&.MuiListItemIcon-root": {
                      minWidth: isOpen ? "35px" : "50px",
                      marginLeft: isOpen ? "0px" : "6px",
                    },
                  }}
                >
                  <img src={item.icon}></img>
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      letterSpacing: "0em",
                      textAlign: "left",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </AccordionSummary>

          <Divider />

          {item?.child?.map((subItem, index) => {
            return (
              <AccordionDetails
                key={index}
                sx={{
                  padding: 0,
                  paddingLeft: "20px",
                  color: "white",
                  background:
                    selectedLink === subItem.childLabel ? "#01997D" : "black",
                  // "&:hover": {
                  //   background: "#3DCBB1",
                  // },
                }}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() =>
                      listBtnHandler(
                        subItem?.parent,
                        subItem?.childLabel,
                        subItem?.route
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "35px",
                        },
                      }}
                    >
                      <img src={subItem.childIcon}></img>
                    </ListItemIcon>
                    <ListItemText
                      primary={subItem?.childLabel}
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "19px",
                          letterSpacing: "0em",
                          textAlign: "left",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </AccordionDetails>
            );
          })}
        </Accordion>
      ))}
    </div>
  );
};

export default MainMenu;

import { Box, Dialog, Grid, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { GET, PUT } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { useDispatch, useSelector } from "react-redux";
import { storeAllRoles } from "../../slices/allroleSlice";
import ButtonCommon from "../../shared/ButtonCommon";
import AutocompleteInput from "../../shared/AutocompleteInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DataTable from "react-data-table-component";
import SearchBar from "../../shared/SearchBar";
import { useTheme } from "@mui/material/styles";

const statusDropdown = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 0,
  },
];

const ManageRoles = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const allRoles = useSelector((state) => state.allroleReducer.allroles);

  const [rolesData, setRolesData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [userDetails, setUserDetails] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  const handleStatusChange = (data) => {
    const putData = {
      userid: data?.UserID,
      status: data?.status === 1 ? 0 : 1,
    };

    const encryptData = EncryptionHelper.encryptData(putData);

    PUT("change-status", { encryptData })
      .then((resp) => {
        const data = EncryptionHelper.decryptData(resp);
        toast.success(data?.message);
        fetchUsers(page);
      })
      .catch((err) => {
        const data = EncryptionHelper.decryptData(err);
        toast.error(data?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);

  const fetchUsers = (page) => {
    setLoading(true);

    GET(
      `users?pageNumber=${page}&pageSize=${perPage}&role=${roleFilter}&status=${statusFilter}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        setData(decryptedData?.users);

        console.log("decryptedData of get users =>", decryptedData);

        setTotalRows(decryptedData?.totalUsers);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const getSearchValue = (value) => {
    setLoading(true);

    GET(
      `users?pageNumber=${page}&pageSize=${perPage}&role=${roleFilter}&status=${statusFilter}&search=${value}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        setData(decryptedData?.users);

        console.log("decryptedData of get users =>", decryptedData);

        setTotalRows(decryptedData?.totalUsers);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(page);
    const response = await GET(
      `users?pageNumber=${page}&pageSize=${newPerPage}`
    );
    const encryptedData = response?.encryptedData;
    const decryptedData = EncryptionHelper.decryptData(encryptedData);

    setData(decryptedData?.users);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    GET("roles")
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        dispatch(storeAllRoles(decryptedData));
      })
      .catch((err) => {
        console.warn("err ==>", err);
      });
  }, []);

  useEffect(() => {
    fetchUsers(1);
  }, [statusFilter, roleFilter]);

  useEffect(() => {
    const dropdownRoles = allRoles.map((item) => {
      return {
        value: item.RoleID,
        label: item.RoleName,
      };
    });
    setRolesData(dropdownRoles);
  }, [allRoles]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const roleClickHandler = (userData) => {
    setUserDetails(userData);
    setOpenDialog(true);
  };

  const searchCase = (search) => {
    console.log("search =>", search);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allroles: { value: userDetails?.RoleID, label: userDetails?.RoleName },
    },
    validationSchema: Yup.object({
      allroles: Yup.object().required("Roles is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        userid: userDetails?.UserID,
        roleid: values?.allroles?.value,
      };

      console.log("data to be encrypted =>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT("update-role", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          fetchUsers(page);
          handleDialogClose();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  const columns = [
    {
      name: <div>User Name</div>,
      sortable: true,
      selector: (row) => (
        <span style={{ cursor: "pointer", color: "#01997D" }}>
          {row.Username}
        </span>
      ),
      wrap: true,
      // grow: 2,
    },
    {
      name: <div>Email</div>,
      selector: (row) => row.Email,
      sortable: true,
      wrap: true,
      // grow: 2,
      // width: "10%",
      // allowOverflow: true,
    },
    {
      name: <div>Role</div>,
      selector: (row) => row.RoleName,
      sortable: false,
      wrap: true,
      // grow: 2,

      // width: "10%",
    },
    {
      name: <div>Status</div>,
      selector: (row) => (
        <>
          {row.status === 1 ? "Active" : "Inactive"}

          <Switch
            size="small"
            checked={row.status === 1 ? true : false}
            onChange={() => handleStatusChange(row)}
          />
        </>
      ),
      sortable: false,
      wrap: true,
      // grow: 2,

      // width: "10%",
    },
    {
      name: <div>Actions</div>,
      cell: (row) => (
        <div className="tbl-btns">
          <AssignmentIndIcon
            onClick={() => {
              roleClickHandler(row);
            }}
            style={{ cursor: "pointer", color: "#01997D" }}
          />
        </div>
      ),
      grow: 1,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        backgroundColor: theme.palette.background.default, // Match background with theme's default
        color: theme.palette.text.primary, // Use primary text color from theme
      },
    },
    headCells: {
      style: {
        backgroundColor: theme.palette.background.paper, // Paper background for header
        color: theme.palette.text.primary, // Primary text color for header
        fontWeight: "bold", // Make header text bold for better contrast
        fontSize: "14px", // Adjust font size as per your preference
      },
    },
    pagination: {
      style: {
        backgroundColor: theme.palette.background.default, // Pagination background matching theme
        color: theme.palette.text.primary, // Pagination text color
      },
      pageButtonsStyle: {
        color: theme.palette.text.primary, // Set the pagination arrow color based on theme
        fill: theme.palette.text.primary, // Arrow icons
        "&:hover": {
          backgroundColor: theme.palette.action.hover, // Hover effect
        },
        "&:disabled": {
          color: theme.palette.text.disabled, // Disabled arrow color
        },
      },
    },
    noData: {
      style: {
        color: theme.palette.text.secondary, // Style for no data message
      },
    },
  };

  return (
    <Box>
      <MainHeader headingText="Manage Roles" />
      <Stack
        gap={3}
        p={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack direction={"row"} gap={6} sx={{ width: "100%" }}>
          <Stack sx={{ width: "100%" }}>
            {" "}
            <Typography sx={{ textAlign: "left" }}>Roles</Typography>
            <Stack>
              <AutocompleteInput
                name="allroles"
                options={rolesData}
                onChange={(event, newValue) => {
                  setRoleFilter(newValue?.value);
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            {" "}
            <Typography sx={{ textAlign: "left" }}>Status</Typography>
            <Stack>
              <AutocompleteInput
                name="allroles"
                options={statusDropdown}
                // value={formik.values.allroles}
                onChange={(event, newValue) => {
                  setStatusFilter(newValue?.value);
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            {" "}
            <Typography sx={{ textAlign: "left" }}>Search</Typography>
            <Stack>
              <SearchBar
                searchHeight={"35px"}
                placeholder={"Search by name or email"}
                searchValue={getSearchValue}
              />
            </Stack>
          </Stack>
        </Stack>
        <DataTable
          // title="Cases"
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          customStyles={customStyles}
        />

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} p={4}>
              {/* 1  */}
              <Grid item xs={12}>
                <Typography variant="h5">
                  Assign role for <b>{userDetails?.Username}</b>
                </Typography>
              </Grid>

              {/* 2  */}
              <Grid item xs={12}>
                <Stack>
                  {" "}
                  <Typography sx={{ textAlign: "left" }}>
                    Roles<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <AutocompleteInput
                      name="allroles"
                      options={rolesData}
                      value={formik.values.allroles}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("allroles", newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        searchCase(newInputValue);
                      }}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.allroles && Boolean(formik.errors.allroles)}
                      // helperText={formik.touched.allroles && formik.errors.allroles}
                    />
                    {formik.touched.allroles && formik.errors.allroles ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.allroles}
                      </div>
                    ) : null}{" "}
                  </Stack>
                </Stack>
              </Grid>

              {/* 3 */}
              <Grid item xs={12}>
                <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
                  <ButtonCommon type="submit">Assign</ButtonCommon>
                  <ButtonCommon btnClicked={handleDialogClose}>
                    Cancel
                  </ButtonCommon>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      </Stack>
    </Box>
  );
};

export default ManageRoles;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import TextInput from "../../TextInput";
import CheckboxInput from "../../CheckboxInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import ButtonCommon from "../../ButtonCommon";
import drawerLogo from "../../../assets/logo.png";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import CloseIcon from "@mui/icons-material/Close";
// import AutocompleteInput from "../../AutocompleteInput";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { POST } from "../../../api/ApiService";
import CaseSearchDropdown from "../../CaseSearchDropdown";

const options = {
  filename: "custodial-form.pdf",
  // default is `save`
  method: "open",
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.LOW,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: "letter",
    // orientation: "landscape",
    // orientation: "portrait",
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    // mimeType: "image/png",
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

// Reusable component for text input with error handling
const FormTextInput = ({ name, label, formik }) => (
  <Stack direction={"row"} alignItems={"center"}>
    <Typography>{label}:</Typography>
    <Box sx={{ width: "400px" }}>
      <TextInput
        name={name}
        variant="standard"
        onChangeValue={formik.handleChange}
        onBlurValue={formik.handleBlur}
        value={formik.values[name]}
        startIcon={false}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div style={{ fontSize: "smaller", color: "red", textAlign: "left" }}>
          {formik.errors[name]}
        </div>
      )}
    </Box>
  </Stack>
);

// Reusable component for checkbox input with error handling
const FormCheckboxInput = ({ name, label, formik }) => (
  <Stack direction={"row"} alignItems={"center"}>
    <CheckboxInput
      name={name}
      onChangeValue={formik.handleChange}
      onBlurValue={formik.handleBlur}
      value={formik.values[name]}
      label={label}
    />
    {formik.touched[name] && formik.errors[name] && (
      <div style={{ fontSize: "smaller", color: "red", textAlign: "left" }}>
        {formik.errors[name]}
      </div>
    )}
  </Stack>
);

const PolicyCheckbox = ({ name, label, formik }) => (
  <Box mb={4}>
    <Typography dangerouslySetInnerHTML={{ __html: label }} />
    <FormCheckboxInput
      name={name}
      label={`I've read and agree to the terms outlined in section ${
        name.split("y")[1]
      }`}
      formik={formik}
    />
  </Box>
);

const CustodialForm = () => {
  // const caseDropdown = useSelector((state) => state.allcaseReducer.allcase);
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [clientSign, setClientSign] = useState();
  const [workerSign, setWorkerSign] = useState();
  const [attachTocase, setAttachTocase] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);

  const clientSigCanvas = useRef({});
  const socialSigCanvas = useRef({});
  const contentRef = useRef(null);

  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const clearClientSignature = () => {
    clientSigCanvas.current.clear();
    setClientSign(null);
  };

  const clearSocialSignature = () => {
    socialSigCanvas.current.clear();
    setWorkerSign(null);
  };

  const saveClientSignature = () => {
    const signatureImage = clientSigCanvas.current.toDataURL();
    // Now you can send `signatureImage` to your API
    setClientSign(signatureImage);
  };

  const saveSocialSignature = () => {
    const signatureImage = socialSigCanvas.current.toDataURL();
    // Now you can send `signatureImage` to your API
    setWorkerSign(signatureImage);
  };

  const handleGeneratePDF = async (caseID) => {
    const content = contentRef.current;
    if (!content) {
      console.error("Content reference is null");
      return;
    }

    try {
      // Get the sections that need to start on new pages
      const sections = content.querySelectorAll(".new-page-section");

      // Initialize jsPDF
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const margin = 20;
      const canvasWidth = pdfWidth - margin * 2;

      console.log(pdfHeight);
      // Loop through sections and add them to PDF
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const canvas = await html2canvas(section, { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        const canvasHeight =
          (section.offsetHeight * canvasWidth) / section.offsetWidth;

        // Add image to PDF
        if (i > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          margin,
          canvasWidth,
          canvasHeight,
          null,
          "FAST"
        );
      }

      const pdfBlob = pdf.output("blob");

      // Prepare form data
      const formData = new FormData();
      formData.append("caseId", caseID);
      formData.append("formType", "custodial parents");
      formData.append("attachmentPath", pdfBlob, `_document.pdf`);
      formData.append("createdBy", userDetails?.id);

      // Send the PDF to the API
      POST("case/case-attachment", formData, true, true)
        .then((resp) => {
          toast.success(resp?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const formik = useFormik({
    initialValues: {
      assignedTo: "",
      methodofCommun: "",
      contactInfo: "",
      email: false,
      phoneCalls: false,
      text: false,
      clientName: "",
      clientAddress: "",
      clientPhone: "",
      clientEmail: "",
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
      policy5: false,
      policy6: false,
      policy7: false,
      policy8: false,
      policy9: false,
      policy10: false,
      policy11: false,
      socialWorker: "",
      case: null,
    },
    validationSchema: Yup.object({
      assignedTo: Yup.string().required("Assigned to is required"),
      methodofCommun: Yup.string().required(
        "Method of communication is required"
      ),
      contactInfo: Yup.string().required("Contact information is required"),
      email: Yup.boolean(),
      phoneCalls: Yup.boolean(),
      text: Yup.boolean(),
      clientName: Yup.string().required("Client name is required"),
      clientAddress: Yup.string().required("Client address is required"),
      clientPhone: Yup.string().required("Client phone number is required"),
      clientEmail: Yup.string().email("Invalid email format"),
      case: Yup.object().required("Case is required"),
    }),
    onSubmit: (values) => {
      saveClientSignature();
      saveSocialSignature();

      setTimeout(() => {
        handleGeneratePDF(values?.case?.CaseID);
      }, [2000]);
    },
  });

  return (
    <Box
      sx={{
        padding: "5px",
        height: "250em",
        overflow: "hidden",
      }}
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <div
            ref={contentRef}
            style={{ borderBottom: "1px solid gray", paddingBottom: "100px" }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Comprehensive Family Services
                </Typography>
                <Typography variant="subtitle1">
                  Forensic & Therapeutic Services
                </Typography>
                <Typography variant="subtitle2">
                  450 Lexington Ave, 4th Floor, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            <br />

            <Stack direction={"row"} justifyContent={"center"}>
              <Typography>
                COMPREHENSIVE FAMILY SERVICES VISITATION POLICIES FOR CUSTODIAL
                PARENTS
              </Typography>
            </Stack>
            <br />
            <br />
            <Typography>
              Comprehensive Family Services (CFS) is a private agency providing
              services of Licensed Social Workers and other professionals. CFS’
              services are appointed/directed by the Courts and may also be
              retained privately.
            </Typography>
            <br />
            <Typography>Date: {new Date().toString()}</Typography>
            {/* case is assigned to  */}
            <FormTextInput
              name="assignedTo"
              label="Your case is assigned to"
              formik={formik}
            />
            <br />
            {/* method of communication  */}
            <FormControl>
              <Typography id="demo-controlled-radio-buttons-group">
                Your social worker's most efficient method of communication is:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="methodofCommun"
                value={formik.values.methodofCommun}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="Phone/Text"
                  control={<Radio color="black" />}
                  label="Phone/Text"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio color="black" />}
                  label="Email"
                />
              </RadioGroup>
              {formik.touched.methodofCommun &&
                formik.errors.methodofCommun && (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.methodofCommun}
                  </div>
                )}
            </FormControl>
            {/* social worker contact information  */}
            <FormTextInput
              name="contactInfo"
              label="Your social worker's contact information is"
              formik={formik}
            />
            <br />
            {/* contact information and preferred method of communication  */}
            <Stack>
              <Typography>
                Please indicate your contact information and preferred method of
                communication by checking as many as necessary:
              </Typography>
              <FormCheckboxInput name="email" label="Email" formik={formik} />
              <FormCheckboxInput
                name="phoneCalls"
                label="Phone Calls"
                formik={formik}
              />
              <FormCheckboxInput name="text" label="Text" formik={formik} />
            </Stack>
            <br />
            <FormTextInput
              name="clientName"
              label="Client Name"
              formik={formik}
            />
            <FormTextInput
              name="clientAddress"
              label="Client Address"
              formik={formik}
            />
            <FormTextInput
              name="clientPhone"
              label="Client Phone"
              formik={formik}
            />
            <FormTextInput
              name="clientEmail"
              label="Client Email"
              formik={formik}
            />

            <br />

            <Typography variant="h5">POLICIES</Typography>

            <br />

            {/* policy 1  */}
            <PolicyCheckbox
              name="policy1"
              label="<b> 1. Substitution: </b> </br> In the event that your social worker is unavailable, your social
          worker may utilize the assistance of another CFS associate. When and
          if this occurs, you will be provided with contact information for the
          delegated social worker."
              formik={formik}
            />

            {/* policy 2  */}
            <PolicyCheckbox
              name="policy2"
              label="<b> 2. Confidentiality: </b> </br> CFS's role is that of a neutral party. This potentially entails
          release of all relevant information gathered and observed throughout
          our involvement with your family to the Court."
              formik={formik}
            />

            {/* policy 3  */}
            <PolicyCheckbox
              name="policy3"
              label="<b>3. Punctuality:</b> <br /> Every effort should be made to arrive at
          your scheduled/instructed time. If the parties adhere to the
          directives of their social worker and are punctual, this should
          prevent either party coming in contact with each other. In the event
          of any potential lateness you must communicate this to your social
          worker as soon as possible and stay in communication with your social
          worker until your arrival. Visiting parents, whose visits occur at the
          CFS office, must arrive fifteen (15) minutes prior to the scheduled
          visitation time and will depart fifteen (15) minutes following the
          custodial parent/child’s departure.
          <br />
          Periodically, your social worker may change this protocol at his/her
          discretion. The protocol for visits conducted in the community is
          directed by the Court or arranged by the parties and/or the social
          worker based on the circumstances of the case. CFS always retains the
          discretion and authority to direct the parties regarding this
          protocol."
              formik={formik}
            />

            {/* policy 4  */}
            <PolicyCheckbox
              name="policy4"
              label="<b>4. Attendance</b> <br/>
          Regular, consistent attendance is necessary in order to comply with visitation requirements. Once a session is scheduled, that time slot is reserved specifically for you. CFS clients are expected to attend all sessions scheduled by their Social Worker except in the event of sickness or emergency. Three or more cancellations during a two-month (60 day) period or two consecutive cancellations may result in services termination and CFS's withdrawal from the case."
              formik={formik}
            />

            {/* policy 5  */}
            <PolicyCheckbox
              name="policy5"
              label="<b>5. Cancellations:</b> <br/>
          All cancellations require a minimum of twenty-four (24) hour notice and must be confirmed by your social worker. Failure to adhere to this policy will result in a cancellation fee charged to you at the same hourly rate for the time you were scheduled, as per signed contract with CFS.
          If you are receiving CFS services free of charge and paid for by the Court and public funding, the Court may still direct that you be responsible for cancelled appointments with CFS. In the event that this is ordered, you will be charged at the hourly rate of $75/hour."
              formik={formik}
            />

            {/* policy 6  */}
            <PolicyCheckbox
              name="policy6"
              label="<b>6.</b> CFS endeavors to provide a neutral, safe and appropriate environment for children to engage in visitation. Discussion about the litigation, disparagement of the other parent, eliciting personal information about the other parent is not permitted during your time at CFS. Your social worker maintains the authority to intervene in and redirect any communication or behavior that is deemed to be inappropriate.<br/> <br/> CFS staff and the CFS office should not be utilized for the purpose of pursuing litigation. CFS policy prohibits service of legal documents before, during or after CFS visitation and in CFS office space."
              formik={formik}
            />

            {/* policy 7  */}
            <PolicyCheckbox
              name="policy7"
              label="<b>7. Scheduling:</b> <br/>
            Scheduling of visits is arranged in consideration of the availability of both parties including the child and the CFS Social Worker. While CFS endeavors to offer flexible staff availability, your cooperation is essential in completing the Court mandated requirements."
              formik={formik}
            />

            {/* policy 8  */}
            <PolicyCheckbox
              name="policy8"
              label="<b>8. Gifts:</b> <br/>
            While at CFS, your child/ren may be offered gifts or other items to take home. Please discuss with your assigned social worker any objections or concerns related to this in advance of visitation commencing."
              formik={formik}
            />

            {/* policy 9  */}
            <PolicyCheckbox
              name="policy9"
              label="<b>9.Food and Toiletries:</b> <br/>
            CFS does not provide diapers or food. Your social worker will discuss with you any specific requirements/supplies needed for your child in advance of your visitation, and who will be responsible for providing these supplies."
              formik={formik}
            />

            {/* policy 10  */}
            <PolicyCheckbox
              name="policy10"
              label="<b>10. Third Parties:</b> <br/>
            Generally, third parties are not permitted to participate in visitation absent consent or authorization of the Court. Please discuss with your assigned social worker any questions/concerns related to other individuals who may be included in your child’s visitation."
              formik={formik}
            />

            {/* client name and signature  */}
            <FormTextInput
              name="clientName"
              label="Client Name:"
              formik={formik}
            />
            <br />

            <Stack direction={"row"} gap={2}>
              <Typography>Signature:</Typography>

              <Box
                sx={{ borderBottom: "1px solid black", width: "fit-content" }}
              >
                <SignatureCanvas
                  ref={clientSigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <Stack
                  direction="row"
                  gap={2}
                  p={2}
                  justifyContent={"flex-end"}
                >
                  <ButtonCommon
                    btnClicked={clearClientSignature}
                    startIcon={false}
                  >
                    Clear
                  </ButtonCommon>
                </Stack>
              </Box>
            </Stack>

            <br />

            {/* social worker name and signature  */}
            <FormTextInput
              name="socialWorker"
              label="Social Worker"
              formik={formik}
            />
            <br />

            <Stack direction={"row"} gap={2}>
              <Typography>Signature:</Typography>

              <Box
                sx={{ borderBottom: "1px solid black", width: "fit-content" }}
              >
                <SignatureCanvas
                  ref={socialSigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <Stack
                  direction="row"
                  gap={2}
                  p={2}
                  justifyContent={"flex-end"}
                >
                  <ButtonCommon
                    btnClicked={clearSocialSignature}
                    startIcon={false}
                  >
                    Clear
                  </ButtonCommon>
                </Stack>
              </Box>
            </Stack>

            <br />

            <Typography>Date: {new Date().toString()}</Typography>
          </div>
          <Stack direction={"row"} p={2} pb={6} gap={4}>
            <ButtonCommon
              startIcon={false}
              btnClicked={() => {
                setAttachTocase(true);
              }}
            >
              Attach to Case
            </ButtonCommon>
            <ButtonCommon
              btnClicked={() => {
                saveClientSignature();
                saveSocialSignature();
                setTimeout(() => {
                  generatePDF(contentRef, options);
                }, [1000]);
              }}
              startIcon={false}
            >
              Download PDF
            </ButtonCommon>
          </Stack>

          {attachTocase && (
            <Stack
              gap={2}
              alignItems={"flex-start"}
              justifyContent={"center"}
              sx={{ width: "fit-content" }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography>Which case would you like to attach to?</Typography>
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAttachTocase(false);
                  }}
                />
              </Stack>
              <Stack>
                <Typography sx={{ textAlign: "left" }}>
                  Select the case<span style={{ color: "red" }}>*</span>
                </Typography>
                <Stack sx={{ width: "400px" }}>
                  {/* <AutocompleteInput
                    id="case"
                    name="case"
                    options={caseDropdown}
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    helperText={formik.touched.case && formik.errors.case}
                  /> */}
                  <CaseSearchDropdown
                    name="case"
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    reset={resetDropdown}
                  />
                  {formik.touched.case && formik.errors.case ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.case}
                    </div>
                  ) : null}{" "}
                </Stack>
              </Stack>
              <ButtonCommon startIcon={false} type={"submit"}>
                Attach
              </ButtonCommon>
            </Stack>
          )}
        </form>
      </Box>

      <Box style={{ height: "250px", width: "100%" }}></Box>
      <br />
      <br />
      <h1>pdf data shown below</h1>
      <div
        ref={contentRef}
        style={{ borderBottom: "1px solid gray", paddingBottom: "100px" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="new-page-section">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Comprehensive Family Services
                </Typography>
                <Typography variant="subtitle1">
                  Forensic & Therapeutic Services
                </Typography>
                <Typography variant="subtitle2">
                  450 Lexington Ave, 4th Floor, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            <br />

            <Stack direction={"row"} justifyContent={"center"}>
              <Typography>
                COMPREHENSIVE FAMILY SERVICES VISITATION POLICIES FOR CUSTODIAL
                PARENTS
              </Typography>
            </Stack>
            <br />
            <br />
            <Typography>
              Comprehensive Family Services (CFS) is a private agency providing
              services of Licensed Social Workers and other professionals. CFS’
              services are appointed/directed by the Courts and may also be
              retained privately.
            </Typography>
            <br />
            <Typography>Date: {new Date().toString()}</Typography>
            {/* case is assigned to  */}
            <FormTextInput
              name="assignedTo"
              label="Your case is assigned to"
              formik={formik}
            />
            <br />
            {/* method of communication  */}
            <FormControl>
              <Typography id="demo-controlled-radio-buttons-group">
                Your social worker's most efficient method of communication is:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="methodofCommun"
                value={formik.values.methodofCommun}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="Phone/Text"
                  control={<Radio color="black" />}
                  label="Phone/Text"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio color="black" />}
                  label="Email"
                />
              </RadioGroup>
              {formik.touched.methodofCommun &&
                formik.errors.methodofCommun && (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.methodofCommun}
                  </div>
                )}
            </FormControl>
            {/* social worker contact information  */}
            <FormTextInput
              name="contactInfo"
              label="Your social worker's contact information is"
              formik={formik}
            />
            <br />
            {/* contact information and preferred method of communication  */}
            <Stack>
              <Typography>
                Please indicate your contact information and preferred method of
                communication by checking as many as necessary:
              </Typography>
              <FormCheckboxInput name="email" label="Email" formik={formik} />
              <FormCheckboxInput
                name="phoneCalls"
                label="Phone Calls"
                formik={formik}
              />
              <FormCheckboxInput name="text" label="Text" formik={formik} />
            </Stack>
            <br />
            <FormTextInput
              name="clientName"
              label="Client Name"
              formik={formik}
            />
            <FormTextInput
              name="clientAddress"
              label="Client Address"
              formik={formik}
            />
            <FormTextInput
              name="clientPhone"
              label="Client Phone"
              formik={formik}
            />
            <FormTextInput
              name="clientEmail"
              label="Client Email"
              formik={formik}
            />

            <br />

            <Typography variant="h5">POLICIES</Typography>

            <br />

            {/* policy 1  */}
            <PolicyCheckbox
              name="policy1"
              label="<b> 1. Substitution: </b> </br> In the event that your social worker is unavailable, your social
          worker may utilize the assistance of another CFS associate. When and
          if this occurs, you will be provided with contact information for the
          delegated social worker."
              formik={formik}
            />

            {/* policy 2  */}
            <PolicyCheckbox
              name="policy2"
              label="<b> 2. Confidentiality: </b> </br> CFS's role is that of a neutral party. This potentially entails
          release of all relevant information gathered and observed throughout
          our involvement with your family to the Court."
              formik={formik}
            />

            {/* policy 3  */}
            <PolicyCheckbox
              name="policy3"
              label="<b>3. Punctuality:</b> <br /> Every effort should be made to arrive at
          your scheduled/instructed time. If the parties adhere to the
          directives of their social worker and are punctual, this should
          prevent either party coming in contact with each other. In the event
          of any potential lateness you must communicate this to your social
          worker as soon as possible and stay in communication with your social
          worker until your arrival. Visiting parents, whose visits occur at the
          CFS office, must arrive fifteen (15) minutes prior to the scheduled
          visitation time and will depart fifteen (15) minutes following the
          custodial parent/child’s departure.
          <br />
          Periodically, your social worker may change this protocol at his/her
          discretion. The protocol for visits conducted in the community is
          directed by the Court or arranged by the parties and/or the social
          worker based on the circumstances of the case. CFS always retains the
          discretion and authority to direct the parties regarding this
          protocol."
              formik={formik}
            />

            {/* policy 4  */}
            <PolicyCheckbox
              name="policy4"
              label="<b>4. Attendance</b> <br/>
          Regular, consistent attendance is necessary in order to comply with visitation requirements. Once a session is scheduled, that time slot is reserved specifically for you. CFS clients are expected to attend all sessions scheduled by their Social Worker except in the event of sickness or emergency. Three or more cancellations during a two-month (60 day) period or two consecutive cancellations may result in services termination and CFS's withdrawal from the case."
              formik={formik}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="new-page-section">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Comprehensive Family Services
                </Typography>
                <Typography variant="subtitle1">
                  Forensic & Therapeutic Services
                </Typography>
                <Typography variant="subtitle2">
                  450 Lexington Ave, 4th Floor, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            {/* policy 5  */}
            <PolicyCheckbox
              name="policy5"
              label="<b>5. Cancellations:</b> <br/>
          All cancellations require a minimum of twenty-four (24) hour notice and must be confirmed by your social worker. Failure to adhere to this policy will result in a cancellation fee charged to you at the same hourly rate for the time you were scheduled, as per signed contract with CFS.
          If you are receiving CFS services free of charge and paid for by the Court and public funding, the Court may still direct that you be responsible for cancelled appointments with CFS. In the event that this is ordered, you will be charged at the hourly rate of $75/hour."
              formik={formik}
            />

            {/* policy 6  */}
            <PolicyCheckbox
              name="policy6"
              label="<b>6.</b> CFS endeavors to provide a neutral, safe and appropriate environment for children to engage in visitation. Discussion about the litigation, disparagement of the other parent, eliciting personal information about the other parent is not permitted during your time at CFS. Your social worker maintains the authority to intervene in and redirect any communication or behavior that is deemed to be inappropriate.<br/> <br/> CFS staff and the CFS office should not be utilized for the purpose of pursuing litigation. CFS policy prohibits service of legal documents before, during or after CFS visitation and in CFS office space."
              formik={formik}
            />

            {/* policy 7  */}
            <PolicyCheckbox
              name="policy7"
              label="<b>7. Scheduling:</b> <br/>
            Scheduling of visits is arranged in consideration of the availability of both parties including the child and the CFS Social Worker. While CFS endeavors to offer flexible staff availability, your cooperation is essential in completing the Court mandated requirements."
              formik={formik}
            />

            {/* policy 8  */}
            <PolicyCheckbox
              name="policy8"
              label="<b>8. Gifts:</b> <br/>
            While at CFS, your child/ren may be offered gifts or other items to take home. Please discuss with your assigned social worker any objections or concerns related to this in advance of visitation commencing."
              formik={formik}
            />

            {/* policy 9  */}
            <PolicyCheckbox
              name="policy9"
              label="<b>9.Food and Toiletries:</b> <br/>
            CFS does not provide diapers or food. Your social worker will discuss with you any specific requirements/supplies needed for your child in advance of your visitation, and who will be responsible for providing these supplies."
              formik={formik}
            />

            {/* policy 10  */}
            <PolicyCheckbox
              name="policy10"
              label="<b>10. Third Parties:</b> <br/>
            Generally, third parties are not permitted to participate in visitation absent consent or authorization of the Court. Please discuss with your assigned social worker any questions/concerns related to other individuals who may be included in your child’s visitation."
              formik={formik}
            />

            <Stack direction={"row"}>
              {/* client name and signature  */}
              <Box sx={{ width: "50%" }}>
                <FormTextInput
                  name="clientName"
                  label="Client Name"
                  formik={formik}
                />
                <br />
                <Stack direction={"row"} gap={2}>
                  <Typography>Signature:</Typography>
                  <img src={clientSign} alt=""></img>
                </Stack>
              </Box>
              {/* social worker name and signature  */}
              <Box sx={{ width: "50%" }}>
                <FormTextInput
                  name="socialWorker"
                  label="Social Worker"
                  formik={formik}
                />
                <br />
                <Stack direction={"row"} gap={2}>
                  <Typography>Signature:</Typography>
                  <img src={workerSign} alt=""></img>
                </Stack>
              </Box>
            </Stack>
            <br />

            <Typography>Date: {new Date().toString()}</Typography>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default CustodialForm;

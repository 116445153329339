import {
  Box,
  Button,
  Link,
  Menu,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
// import SearchBar from "../SearchBar";

// assets
// import settingIcon from "../../assets/settingIcon.svg";
import userIcon from "../../assets/userIcon.svg";
// import logoutLogo from "../../assets/logoutLogo.png";
import cblogo from "../../assets/CBlogo.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { POST } from "../../api/ApiService";
import DarkLightMode from "../DarkLightMode";

const MainHeader = ({ headingText }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const userData = useSelector((state) => state.userReducer.userDetails);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userIconHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const signoutHandler = () => {
    POST("logout")
      .then((result) => {
        toast.success(result?.message);
        localStorage.removeItem("token");
        navigate("/login");
      })
      .catch((err) => {
        console.warn("err api call =>", err);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "15px",
      }}
    >
      <Stack direction="row" alignItems="baseline">
        {!belowTablet && (
          <Typography
            variant="h5"
            noWrap
            sx={{
              fontWeight: "500",
              lineHeight: "49px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            {headingText}{" "}
          </Typography>
        )}
      </Stack>
      <Stack
        direction="row"
        gap={2}
        justifyContent={"flex-end"}
        sx={{ width: "35%", minWidth: "270px" }}
      >
        <DarkLightMode />
        <img
          src={userIcon}
          alt=""
          style={{ width: "35px", cursor: "pointer" }}
          onClick={userIconHandler}
        ></img>
      </Stack>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiMenu-list": {
            paddingTop: 2,
            paddingBottom: 2,
          },
        }}
      >
        <Stack gap={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
            pl={2}
            pr={2}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Comprehensive Family Services
            </Typography>
            <Button
              color="red"
              variant="contained"
              sx={{
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
              onClick={signoutHandler}
            >
              Sign Out
            </Button>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            pl={2}
            pr={2}
            gap={4}
          >
            <img src={cblogo} alt=""></img>
            <Stack gap={1}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "300",
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {userData?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "300",
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {userData?.email}
              </Typography>
              <Link
                sx={{
                  fontSize: "16px",
                  fontWeight: "300",
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#00A3FF",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/profile")}
              >
                View account
              </Link>
            </Stack>
          </Stack>
          {/* <Stack
            direction={"row"}
            alignItems={"center"}
            p={1}
            pl={2}
            pr={2}
            gap={2}
            sx={{ background: "#C5C5C5" }}
          >
            <img src={logoutLogo} alt=""></img>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "6px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#000000",
              }}
            >
              Sign in with a Different account
            </Typography>
          </Stack> */}
        </Stack>
      </Menu>
    </Box>
  );
};

export default MainHeader;

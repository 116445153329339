import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { DELETE, GET } from "../../api/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EncryptionHelper from "../../shared/EncryptionHelper";
import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { storeEditDar } from "../../slices/editDarSlice";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

const MyDar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [data, setData] = useState([]);
  const [totalTime, setTotalTime] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");

  const darColums = [
    {
      name: <div>Actions</div>,
      cell: (row) => (
        <div className="tbl-btns">
          <EditIcon
            onClick={() => {
              navigate("/editdar");
              dispatch(storeEditDar(row));
            }}
          />
        </div>
      ),
      grow: 1,
    },
    {
      name: <div>Social Worker</div>,
      selector: (row) => (row.WorkerName ? row.WorkerName : "null"),
      sortable: true,
      wrap: true,
      // grow: 2,
    },
    {
      name: <div>Case Name</div>,
      selector: (row) => row.CaseName,
      sortable: true,
      wrap: true,
      // grow: 2,
      // width: "10%",
      // allowOverflow: true,
    },

    {
      name: <div>Case Number</div>,
      selector: (row) => row.CaseNumber,
      sortable: true,
      wrap: true,
      // grow: 2,

      // width: "10%",
    },
    {
      name: <div>Appointment Start</div>,
      selector: (row) => moment(row.StartTime).format("LLL"),
      sortable: true,
      wrap: true,
      // grow: 2,

      // width: "10%",
    },
    {
      name: <div>Appointment End</div>,
      selector: (row) => moment(row.FinishTime).format("LLL"),
      sortable: true,
      wrap: true,
      // grow: 2,

      // width: "10%",
    },
    {
      name: <div>Service</div>,
      selector: (row) => row.ServiceOrActivity,
      sortable: true,
      wrap: true,
      // grow: 2,

      // width: "10%",
    },
  ];

  useEffect(() => {
    fetchDar(1);
  }, []);

  useEffect(() => {
    fetchDar(1);
  }, [activeFilter]);

  const fetchDar = (page) => {
    setLoading(true);

    GET(
      `daily-activity/get-reports/${userDetails?.id}?pageNumber=${page}&pageSize=${perPage}&period=${activeFilter}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decryptedData of dar =>", decryptedData);

        // setData(decryptedData?.reports);
        // setTotalTime(decryptedData?.totalTime);
        // setTotalRows(decryptedData?.totalData);
        // setLoading(false);

        const reports = decryptedData?.reports;

        // Function to parse and convert time
        const parseTime = (timeStr) => {
          const [hours, minutes, seconds] = timeStr.split(":").map(Number);
          return (
            (hours || 0) * 3600 + // convert hours to seconds
            (minutes || 0) * 60 + // convert minutes to seconds
            (seconds || 0) // seconds
          );
        };

        // Function to format total seconds into hours:minutes:seconds
        const formatTime = (totalSeconds) => {
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          return `${hours}:${minutes}:${seconds}`;
        };

        // Function to sum time spent from reports
        const sumTimeSpent = (data) => {
          let totalSeconds = 0;

          data.forEach((item) => {
            totalSeconds += parseTime(item.TimeSpent);
          });

          return formatTime(totalSeconds);
        };

        // Sum the time spent in all reports
        const totalTimeSpent = sumTimeSpent(reports);

        // Set data and total time
        setData(reports);
        setTotalTime(totalTimeSpent); // Update this line to set the formatted total time
        setTotalRows(decryptedData?.totalData);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    fetchDar(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await GET(
      `daily-activity/get-reports/${userDetails?.id}?pageNumber=${page}&pageSize=${newPerPage}`
    );
    const encryptedData = response?.encryptedData;
    const decryptedData = EncryptionHelper.decryptData(encryptedData);

    setData(decryptedData?.reports);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleselectedRowChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);

    const deleteIds = selectedRows?.map((item) => item.ReportID);
    setDeleteIds(deleteIds);
  };

  const deleteBtnHandler = () => {
    const deleteData = {
      ids: deleteIds,
    };

    console.log("data to be encrypted =>", deleteData);

    const encryptData = EncryptionHelper.encryptData(deleteData);

    DELETE(`daily-activity/delete`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
        setToggleClearRows(!toggledClearRows);
        fetchDar(1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const lastpayClickHandler = () => {
    setActiveFilter("last");
  };

  const currentpayClickHandler = () => {
    setActiveFilter("current");
  };

  const allActivityClickHandler = () => {
    setActiveFilter("all");
  };

  const customStyles = {
    rows: {
      style: {
        backgroundColor: theme.palette.background.default, // Match background with theme's default
        color: theme.palette.text.primary, // Use primary text color from theme
      },
    },
    headCells: {
      style: {
        backgroundColor: theme.palette.background.paper, // Paper background for header
        color: theme.palette.text.primary, // Primary text color for header
        fontWeight: "bold", // Make header text bold for better contrast
        fontSize: "14px", // Adjust font size as per your preference
      },
    },
    pagination: {
      style: {
        backgroundColor: theme.palette.background.default, // Pagination background matching theme
        color: theme.palette.text.primary, // Pagination text color
      },
      pageButtonsStyle: {
        color: theme.palette.text.primary, // Set the pagination arrow color based on theme
        fill: theme.palette.text.primary, // Arrow icons
        "&:hover": {
          backgroundColor: theme.palette.action.hover, // Hover effect
        },
        "&:disabled": {
          color: theme.palette.text.disabled, // Disabled arrow color
        },
      },
    },
    noData: {
      style: {
        color: theme.palette.text.secondary, // Style for no data message
      },
    },
  };

  return (
    <Box>
      <MainHeader headingText="My DARs" />

      <Stack
        gap={3}
        p={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack direction={"row"} gap={4} alignItems={"center"}>
          <Button
            startIcon={false}
            variant={activeFilter === "all" ? "contained" : "outlined"}
            color={"shamrock"}
            sx={{ color: activeFilter === "all" ? "white" : "black" }}
            onClick={allActivityClickHandler}
          >
            All Activity
          </Button>
          <Button
            startIcon={false}
            variant={activeFilter === "last" ? "contained" : "outlined"}
            color={"shamrock"}
            sx={{ color: activeFilter === "last" ? "white" : "black" }}
            onClick={lastpayClickHandler}
          >
            Last Pay Period
          </Button>
          <Button
            startIcon={false}
            variant={activeFilter === "current" ? "contained" : "outlined"}
            color={"shamrock"}
            sx={{ color: activeFilter === "current" ? "white" : "black" }}
            onClick={currentpayClickHandler}
          >
            Current Pay Period
          </Button>

          {selectedRows?.length > 0 && (
            <Button
              variant="contained"
              color="red"
              sx={{
                color: "white",
              }}
              onClick={deleteBtnHandler}
            >
              Delete
            </Button>
          )}
        </Stack>

        <DataTable
          columns={darColums}
          data={data}
          progressPending={loading}
          progressComponent={
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "100%",
                height: "50vh",
                background: theme.palette.background.default,
              }}
            >
              <CircularProgress
                style={{
                  color: theme.palette.primary.main,
                }}
              />
            </Stack>
          }
          selectableRows
          onSelectedRowsChange={handleselectedRowChange}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          clearSelectedRows={toggledClearRows}
          customStyles={customStyles}
        />

        <Stack direction="row" justifyContent={"center"}>
          <Typography>
            <b>Total Time:</b> {totalTime} hours
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MyDar;

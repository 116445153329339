import React from 'react'

const NotFoundPage = () => {
    return (
        <div>
            <h4>Page Not Found</h4>
        </div>
    )
}

export default NotFoundPage

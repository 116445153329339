import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#3DCBB1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFD130",
    },
    shamrock: {
      main: "#3DCBB1",
    },
    black: {
      main: "#000000",
    },
    red: {
      main: "#C42A22",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,

    button: {
      textTransform: "none",
    },
  },
  // components: {
  //   MuiPaper: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: "#000000",
  //         color: "#fff",
  //       },
  //     },
  //   },
  // },
});

export default lightTheme;

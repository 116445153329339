import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

//  Import your reducers
import userReducer from "../slices/userSlice";
// import alluserReducer from "../slices/alluserSlice";
import allroleReducer from "../slices/allroleSlice";
import editcaseReducer from "../slices/editCaseSlice";
import editnoteReducer from "../slices/editnotesSlice";
import allcaseReducer from "../slices/allCaseSlice";
import casedetailReducer from "../slices/caseDetailSlice";
import editDarReducer from "../slices/editDarSlice";
import editOrderReducer from "../slices/editOrderSlice";
import themeColorReducer from "../slices/themeSlice";

// Create a root reducer
const rootReducer = combineReducers({
  userReducer,
  // alluserReducer,
  editcaseReducer,
  editnoteReducer,
  allcaseReducer,
  casedetailReducer,
  editDarReducer,
  editOrderReducer,
  allroleReducer,
  themeColorReducer,
});

// 4. Configure persistence only for certain slices
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "themeColorReducer",
    "userReducer",
    "alluserReducer",
    "allcaseReducer",
    "casedetailReducer",
    "editDarReducer",
    "editOrderReducer",
    "editcaseReducer",
    "editnoteReducer",
  ], // Only reducer1 and reducer3 will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// 5. Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
});

// 6. Create the persisted store
export const persistor = persistStore(store);
